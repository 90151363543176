import { render, staticRenderFns } from "./profession-vote-popup.vue?vue&type=template&id=2b087655&lang=pug&"
import script from "./profession-vote-popup.vue?vue&type=script&lang=js&"
export * from "./profession-vote-popup.vue?vue&type=script&lang=js&"
import style0 from "./profession-vote-popup.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports