<template lang="pug">
    welcome-info
        template(v-slot:title) {{ text.title }}
        template(v-slot:subtitle)
            span(v-html="text.subtitle")
        template(v-slot:content)
            .welcome-test
                transition(name="fadeIn")
                    loading(v-if="!answerReady")._loading
                ._title {{ locale("choose-title") }}
                ._progress
                    ._progress-item(v-for="(progress, index) in test" :style="`width: ${100 / test.length}%`" :class="{active: index === activeIndex}" :key="progress.id")
                transition-group(name="test-fade")
                    ._question(v-for="(question, index) in test" :key="question.id" v-if="index === activeIndex")
                        ._answer(v-for="answer in question.values" @click="applyAnswer(answer.id)" :class="{active: questionAnswer === answer.id}") {{ answer.value }}
                //ui-button(:text="locale('next-question')" :disabled="!questionAnswer" @click.native="applyAnswer")._next-question
                blockquote._quote {{ quote }}
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import declination from "@/utils/declination";
import UiCheckbox from "@/components/ui/ui-checkbox/ui-checkbox";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomeTest",
    components: { UiButton, UiCheckbox, WelcomeInfo },
    data() {
        return {
            test: [],
            activeIndex: 0,
            answerReady: false,
            questionAnswer: null,
            answers: [],
            numberOfTests: -1,
        };
    },
    computed: {
        activeQuestion: (state) => state.test[state.activeIndex],
        text: function () {
            const text = {
                title: this.locale("title-1"),
                subtitle: `${this.locale("subtitle-1")} ${
                    this.test.length
                } ${this.declination(this.test.length, [
                    this.locale("question-destination-1"),
                    this.locale("question-destination-2"),
                    this.locale("question-destination-3"),
                ])}.`,
            };

            if (this.numberOfTests === 1) {
                text.title = this.locale("title-2");
                text.subtitle = `${this.locale("subtitle-2")} ${
                    this.test.length
                } ${this.declination(this.test.length, [
                    this.locale("question-destination-1"),
                    this.locale("question-destination-2"),
                    this.locale("question-destination-3"),
                ])}.`;
            }

            if (this.numberOfTests === 2) {
                text.title = this.locale("title-3");
                text.subtitle = this.locale("subtitle-3", {
                    count: this.test.length,
                });
            }

            return text;
        },
        quote: function () {
            let text = this.locale("quote-1");

            if (this.numberOfTests === 1) {
                text = this.locale("quote-2");
            }

            if (this.numberOfTests === 2) {
                text = this.locale("quote-3");
            }

            return text;
        },
    },
    async mounted() {
        this.yandexGoal("test_start");
        await this.getTest();

        await this.$store.dispatch("set", {
            name: "onboardingCtrlStepNumber",
            value: 1,
        });

        const completedSteps = await this.getOnBoardingCompletedSteps();
        let lastStepIndex = 0;
        if (completedSteps.length) {
            lastStepIndex = completedSteps.reduce((prev, current) =>
                current.step_number > prev.step_number ? current : prev,
            ).step_number;
        }

        if (lastStepIndex === 6) {
            this.numberOfTests = 1;
        }
    },
    methods: {
        declination,
        async getTest() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 8,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const test = r.data.onBoardingGetStep.test;
                    if (test === null || !test.length) {
                        this.redirectController();
                        return;
                    }

                    this.numberOfTests++;
                    this.test = test;
                    this.activeIndex = 0;
                    this.answerReady = true;
                })
                .catch(() => {
                    this.redirectController();
                });
        },
        async applyAnswer(id) {
            this.questionAnswer = id;
            this.answers.push(Number(this.questionAnswer));
            this.questionAnswer = null;

            if (this.activeIndex + 1 === this.test.length) {
                this.answerReady = false;
                await this.sendAnswers();
                await this.getTest();
                return;
            }
            this.activeIndex++;
        },
        async sendAnswers() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 8,
                        result: this.answers,
                    },
                })
                .then(() => {
                    this.yandexGoal(`test_${this.numberOfTests + 1}_end`);
                    this.answers = [];
                })
                .catch(e => {
                    if(e.graphQLErrors[0].extensions.code === 1007) {
                        this.redirectController();
                        return;
                    }
                    console.log(e);
                });
        },
    },
};
</script>

<style lang="scss">
.welcome-test {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    .loading {
        position: absolute;
        background: #000;
        opacity: 1;
        transition: 0.4s;
    }

    &__title {
        font-size: 24px;
        line-height: 1.5;
        font-weight: bold;
    }

    &__progress {
        display: flex;
        justify-content: center;
        margin: 40px 0 60px 0;
        width: 80%;
        @media (max-width: 767px) {
            margin: 30px 0 40px;
        }
    }

    &__progress-item {
        height: 2px;
        width: 40px;
        background: rgba(#fff, 0.2);
        transition: 0.4s;

        &.active {
            background: rgba(#fff, 0.65);
        }

        &:not(:first-child) {
            margin-left: 5px;
        }
    }

    &__answer {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        max-width: 320px;
        height: 240px;
        width: 50%;
        padding: 0 20px;
        font-size: 18px;
        line-height: 1.5;
        background: #232323;
        border: 1px solid transparent;
        transition: 0.4s;
        @media (max-width: 480px) {
            width: 100%;
            max-width: 100%;
            height: initial;
            padding: 40px;
        }

        &.active {
            border-color: $gold;
            color: $gold;
        }

        &:hover {
            cursor: pointer;
        }

        &:not(:first-child) {
            margin-left: 30px;
            @media (max-width: 480px) {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }

    &__checkbox {
        text-align: left;
    }

    &__next-question {
        margin-top: 50px;
    }

    &__question {
        display: flex;
        min-height: 150px;
        @media (max-width: 480px) {
            flex-direction: column;
        }
    }

    &__quote {
        color: $gold;
        margin-top: 15px;
    }
}
</style>
