<template lang="pug">
  welcome-info
    template(v-slot:title) {{ locale("title") }}
    template(v-slot:subtitle) {{ locale("subtitle") }}
    template(v-slot:content)
      .welcome-photo-test
        transition(name="fadeIn")
          loading(v-if="!answerReady")._loading

        ._title {{ locale("choose-title") }}
        ._progress
          ._progress-item(v-for="(progress, index) in test.length / 2" :style="`width: ${100 / test.length}%`" :class="{active: index === activeIndex}" :key="index")
        ._list
          ._photo-box(v-for="photo in pair" :key="photo.id" :class="{active: photo.checked}" @click="choosePhoto(photo)" v-if="answerReady")
            img(:src="`https://merlinface.com${photo.value}`")._photo
        //ui-button(:text="locale('next-btn')" :disabled="!questionAnswer" @click.native="applyAnswer")._next-question
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import declination from "@/utils/declination";
import UiCheckbox from "@/components/ui/ui-checkbox/ui-checkbox";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomePhotoTest",
    components: { UiButton, UiCheckbox, WelcomeInfo },
    data() {
        return {
            test: [],
            activeIndex: 0,
            answerReady: false,
            answers: [],
            questionAnswer: null,
        };
    },
    computed: {
        activeQuestion: (state) => state.test[state.activeIndex],
        pair: function () {
            const pair = [];

            const firstEl = this.activeIndex * 2;
            const secondEl = this.activeIndex * 2 + 1;

            console.log(firstEl, secondEl);

            pair.push(this.test[firstEl]);
            pair.push(this.test[secondEl]);

            return pair;
        },
    },
    mounted() {
        this.yandexGoal("photo_test_start");
        this.getTest();
        this.$store.dispatch("set", {
            name: "onboardingCtrlStepNumber",
            value: 2,
        });
    },
    methods: {
        declination,
        choosePhoto(photo) {
            this.questionAnswer = photo.id;
            this.pair.forEach((photo) => (photo.checked = false));
            photo.checked = true;
            this.applyAnswer();
        },
        async getTest() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 9,
                    },
                })
                .then((r) => {
                    r.data.onBoardingGetStep.poll.values.forEach((photo) => {
                        this.test.push({
                            ...photo,
                            checked: false,
                        });
                    });
                    setTimeout(() => {
                        this.answerReady = true;
                    }, 300);
                });
        },
        async applyAnswer() {
            this.answers.push(Number(this.questionAnswer));
            this.questionAnswer = null;
            this.activeIndex++;

            if (this.activeIndex === this.test.length / 2) {
                this.answerReady = false;
                await this.sendAnswers();
                await this.$router.push("/welcome/analyze");
            }
        },
        async sendAnswers() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 9,
                        result: this.answers,
                    },
                })
                .then(() => {
                    this.$store.dispatch("set", {
                        name: "nextOnboardingStepIndex",
                        value: 3,
                    });
                    this.test = [];
                    this.activeIndex = 0;
                    this.answers = [];
                });
        },
    },
};
</script>

<style lang="scss">
.welcome-photo-test {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loading {
        position: absolute;
        opacity: 0.8;
        transition: 0.4s;
    }
    &__title {
        position: relative;
        font-weight: bold;
        font-size: 24px;
        /*        &:after {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 10px;
            width: 18px;
            height: 22px;
            background: url(../../assets/svg/arrow-down.svg) center/cover no-repeat;
            animation: arrowAnimation 1s infinite linear;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: -30px;
            right: 10px;
            width: 18px;
            height: 22px;
            background: url(../../assets/svg/arrow-down.svg) center/cover no-repeat;
            animation: arrowAnimation 1s infinite linear;
        }*/
    }
    &__progress {
        display: flex;
        justify-content: center;
        margin: 40px 0 60px 0;
        width: 80%;
        @media (max-width: 767px) {
            margin: 30px 0 40px;
        }
    }
    &__progress-item {
        height: 2px;
        width: 40px;
        background: rgba(#fff, 0.3);
        transition: 0.4s;
        &.active {
            background: #fff;
        }
        &:not(:first-child) {
            margin-left: 5px;
        }
    }
    &__list {
        display: flex;
        width: 100%;
        min-height: 300px;
        @media (max-width: 767px) {
            min-height: 200px;
        }
        @media (max-width: 430px) {
            min-height: 150px;
        }
    }
    &__photo-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 300px;
        padding: 3px;
        border: 3px solid transparent;
        transition: 0.4s;
        @media (max-width: 767px) {
            width: 200px;
            height: 200px;
        }
        @media (max-width: 430px) {
            width: 50%;
            height: auto;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:not(:first-child) {
            margin-left: 10px;
        }
        &:hover {
            cursor: pointer;
        }
        &.active {
            border-color: $gold;
        }
    }
    &__next-question {
        margin-top: 50px;
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }
}
@keyframes arrowAnimation {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-5px);
    }
}
</style>
