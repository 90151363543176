<template lang="pug">
    welcome-info
        template(v-slot:title) {{ title }}
        template(v-slot:subtitle) {{ subtitle }}
        template(v-slot:content)
            loading(v-if="!isReady")
            ui-button(@click.native="handleBtn()" :text="btnText")
</template>
<script>
import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomeCheckPay",
    components: {UiButton, WelcomeInfo},
    data() {
        return {
            isReady: false,
            isSuccess: false,
        };
    },
    computed: {
        title: (state) =>
            state.isSuccess ? state.locale("title-success") : state.locale("title-error"),
        subtitle: (state) =>
            state.isSuccess
                ? state.locale("description-success")
                : state.locale("description-error"),
        btnText: (state) =>
            state.isSuccess ? state.locale("btn-success") : state.locale("btn-error"),
    },
    watch: {
        isSuccess(status) {
            if (status) {
                setTimeout(() => {
                    this.handleBtn();
                }, 6000);
            }
        },
    },
    mounted() {
        this.checkPay();
    },
    methods: {
        async checkPay() {
            const selectedOnBoardingType = localStorage.getItem("selectedOnBoardingType");
            console.log(+selectedOnBoardingType === 60 ? 11 : 7);
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: +selectedOnBoardingType === 60 ? 11 : 7,
                        result: 1,
                    },
                })
                .then(() => {
                    this.yandexGoal("succeed_pay");
                    this.isSuccess = true;
                })
                .catch((e) => {
                    this.yandexGoal("fail_pay");
                    if (Number(e.graphQLErrors[0].extensions.code) === 1007) {
                        this.isSuccess = true;
                    }
                })
                .finally(() => {
                    this.isReady = true;
                });
        },
        async handleBtn() {
            const selectedOnBoardingType = await this.getOnBoardingCompletedSteps();
            const firstOnBoardingStep = selectedOnBoardingType.find(
                (step) => step.step_number === 1,
            );
            if (firstOnBoardingStep.step_result_int === 60) {
                if (this.isSuccess) {
                    await this.$router.push("/welcome/analyze-ready");
                } else {
                    await this.$router.push("/welcome/prof-pay");
                }
            }
            if (firstOnBoardingStep.step_result_int === 61) {
                if (this.isSuccess) {
                    await this.$router.push("/welcome/test");
                } else {
                    await this.$router.push("/welcome/psychotype-pay");
                }
            }

        },
    },
};
</script>
