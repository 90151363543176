<template lang="pug">
    .profession-vote-popup
        ._wrap(v-click-outside="closeModal")
            ._inner
                transition(name="fadeIn")
                    svg-icon(icon-name="down-arrow" v-if="showScroll")._scroll-icon
                ._content(@ps-y-reach-end="showScroll = false" @ps-scroll-up="showScroll = true")
                    transition(name="fadeIn" mode="out-in")
                        ._head(v-if="[1, 2, 4, 6].indexOf(currentStage) >= 0" key="head")
                            ._title {{ profession.name }}
                            ._description {{ profession.description }}
                        ._help(v-if="helps && currentStage === 3" data-stage="3" key="help-3")
                            ._help-wrap
                                svg-icon(iconName="onboard-tip-arrow")._help-arrow
                                ._help-text {{ locale("help-1") }}
                        ._help(v-if="helps && currentStage === 5" data-stage="5" key="help-5")
                            ._help-wrap
                                svg-icon(iconName="onboard-tip-arrow")._help-arrow
                                ._help-text {{ locale("help-2") }}
                    transition(name="fadeIn" mode="out-in")
                        ._help(v-if="helps && currentStage === 1" data-stage="1" key="help")
                            ._help-wrap
                                svg-icon(iconName="onboard-tip-arrow")._help-arrow
                                ._help-text {{ locale("help-3") }}
                                ui-button(:text="locale('next-btn')" @click.native="currentStage = 2")._help-btn
                        ._list(v-if="currentStage !== 1" key="list" ref="list")
                            transition-group(name="fadeIn")
                                onboard-description-popup-radio(
                                    v-for="(p, index) in profession.professions"
                                    :title="p",
                                    :error="!votes[p] && isError"
                                    :show-description="false"
                                    @vote="changeProfessionVote"
                                    :stage="completedCount"
                                    v-if="currentStage === 2 ? index === 0 : true"
                                    :style="{'transition-delay': `${index * 0.4}s`}"
                                    :key="p"
                                    @clickOnTitle="currentStage = 6"
                                )._list-item
                    transition(name="fadeIn" :delay="{ enter: 400, leave: 0 }")
                        ._help(v-if="helps && currentStage === 2" data-stage="2" key="help-2")
                            ._help-wrap
                                svg-icon(iconName="onboard-tip-arrow")._help-arrow
                                ._help-text(v-html="locale('help-4')")
            ._footer(v-if="currentStage > 2")
                ui-button._calculate-btn(@click.native="submitHandler" :text="locale('calc-btn')" color="gold")
                transition-expand
                    ._error(v-if="isError && noVoteProfessions.length") {{ locale("error") }}
                        br/ {{ noVoteProfessions.join(", ") }}
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import OnboardDescriptionPopupRadio from "@/components/app/onboard-description-popup/onboard-description-popup-radio/onboard-description-popup-radio";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";

export default {
    name: "ProfessionVotePopup",
    components: { TransitionExpand, OnboardDescriptionPopupRadio, UiButton },
    props: {
        profession: {
            type: Object,
            required: true,
            default: () => ({
                id: 0,
                name: "",
                description: "",
                professions: [],
            }),
        },
        helps: {
            type: Boolean,
            default: false,
            required: false,
        },
        professionHelp: {
            type: Boolean,
            default: false,
            required: false,
        },
        completedCount: {
            type: Number,
            default: 1,
            required: true,
        },
        stage: {
            type: Number,
            default: 0,
            required: true,
        },
    },
    data() {
        return {
            showScroll: true,
            isError: false,
            votes: {},
            currentStage: 1,
        };
    },
    computed: {
        result: function () {
            const result = [];
            this.profession.professions.forEach((p) => {
                const professionArray = [];
                professionArray.push(p);
                professionArray.push(this.votes[p]);
                result.push(professionArray);
            });
            return result;
        },
        noVoteProfessions: function () {
            const professionArray = [];
            this.profession.professions.forEach((p) => {
                if (!this.votes[p]) {
                    professionArray.push(p);
                }
            });
            return professionArray;
        },
        calculateAvailable: function () {
            return (
                this.profession.professions.length !==
                Object.keys(this.votes).length
            );
        },
    },
    created() {
        this.currentStage = this.stage;
        this.showProfessionHelp = this.professionHelp;
        if (process.env.NODE_ENV === "development" || localStorage.getItem("mode") === "development") {
            this.noVoteProfessions.forEach(item => {
                this.changeProfessionVote(item, 3);
            });
        }
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        submitHandler() {
            if (!this.calculateAvailable) {
                this.sendVotes();
                return;
            }
            this.isError = true;
            setTimeout(() => {
                let hiddenError = document.querySelector(".error");
                hiddenError.scrollIntoView({
                    block: "end",
                    behavior: "smooth",
                });
            }, 200);
        },
        changeProfessionVote(name, value) {
            console.log("name", name, "value", value);
            this.$set(this.votes, name, value);

            if (this.currentStage === 3) {
                this.currentStage = 4;
            }

            if (this.currentStage === 2) {
                this.currentStage = 3;
            }
        },
        async sendVotes() {
            this.yandexGoal(`complete_profession_vote_${this.completedCount}`);
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 10,
                        result: this.result,
                    },
                })
                .then(() => {
                    this.$emit("getNewProfession");
                });
        },
        getVoteLimit(index) {
            if (this.currentStage === 2) {
                return index === 0;
            }
            return index > 0;
        },
    },
};
</script>

<style lang="scss">
@import "profession-vote-popup";
</style>
