<template lang="pug">
  welcome-info
    template(v-slot:title) Таланты и антиталанты
    template(v-slot:subtitle) Нажмите на таланты
    template(v-slot:content)
      .welcome-talents(:class="{hidden: showPopup}")
        svg(viewBox="-205 -205 410 410" v-if="categories.length")._illustration
          g._pie-group
            path(d="M -104.97023387608508 -2.499999999999982 A 105 105 0 0 1 104.97023387608508 -2.500000000000067 L 19.84313483298443 -2.500000000000006 A 20 20 0 0 0 -19.84313483298443 -2.4999999999999987 L -104.97023387608508 -2.499999999999982 Z" :class="{current: categories[0].unlocked}")._pie-section
            svg(:x="-6" :y="-80" width="11" height="16" viewBox="0 0 16 23")._unlocked
              path(d="M14.347,22.629 L1.652,22.629 C0.919,22.629 0.324,22.10 0.324,21.248 L0.324,10.533 C0.324,9.770 0.919,9.152 1.652,9.152 L2.449,9.152 L2.449,6.170 C2.449,2.967 4.946,0.370 8.26,0.370 C11.106,0.370 13.603,2.967 13.603,6.170 L13.603,9.152 L14.347,9.152 C15.80,9.152 15.675,9.770 15.675,10.533 L15.675,21.248 C15.675,22.10 15.80,22.629 14.347,22.629 ZM12.541,6.252 C12.541,3.675 10.531,1.585 8.53,1.585 C5.574,1.585 3.564,3.675 3.564,6.252 L3.564,9.152 L12.541,9.152 L12.541,6.252 ZM14.612,10.919 C14.612,10.553 14.327,10.257 13.975,10.257 L2.24,10.257 C1.672,10.257 1.386,10.553 1.386,10.919 L1.386,20.861 C1.386,21.227 1.672,21.524 2.24,21.524 L13.975,21.524 C14.327,21.524 14.612,21.227 14.612,20.861 L14.612,10.919 ZM9.141,16.968 L9.141,18.403 C9.141,19.59 8.630,19.591 7.999,19.591 C7.369,19.591 6.857,19.59 6.857,18.403 L6.857,16.937 C6.189,16.522 5.742,15.764 5.742,14.896 C5.742,13.585 6.764,12.521 8.26,12.521 C9.287,12.521 10.310,13.585 10.310,14.896 C10.310,15.786 9.839,16.562 9.141,16.968 Z")
            svg(width="60px" height="50px" :x="-30" :y="-59")
              foreignObject(width="100%" height="30")._text-box
                p._text(:class="{current: categories[0].unlocked}")
                  span(v-for="(text, index) in categories[0].name.split(\" и \")" :key="index") {{ index > 0 ? `и ${text}` : text }}
          g._pie-group(@click="openPopup(true)")
            path(d="M 104.97023387608508 2.5000000000000155 A 105 105 0 0 1 -104.97023387608508 2.4999999999999867 L -19.84313483298443 2.4999999999999907 A 20 20 0 0 0 19.84313483298443 2.499999999999996 L 104.97023387608508 2.5000000000000155 Z" :class="{current: categories[1].unlocked}")._pie-section
            svg(width="60px" height="50px" :x="-30" :y="50")
              foreignObject(width="100%" height="30")._text-box
                p._text(:class="{current: categories[1].unlocked}")
                  span(v-for="(text, index) in categories[1].name.split(\" и \")" :key="index") {{ index > 0 ? `и ${text}` : text }}
        transition(name="welcome-fade")
          ._popup(v-if="showPopup")
            ._popup-title Ваши таланты
            ._popup-wrap
              transition(name="fadeIn")
                svg-icon(icon-name="down-arrow" v-if="showScroll")._scroll-icon
              perfect-scrollbar._popup-list(@ps-y-reach-end="showScroll = false" @ps-scroll-up="showScroll = true")
                ._popup-item(v-for="talent in talents") {{ talent }}
            ui-button(text="Понял, идём дальше" @click.native="completeStep()")._popup-btn
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import declination from "@/utils/declination";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import OnboardDescriptionPopup from "@/components/app/onboard-description-popup/onboard-description-popup";
import UiButton from "@/components/ui/ui-button/ui-button";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";

export default {
    name: "WelcomeStart",
    components: { UiButton, WelcomeInfo, OnboardDescriptionPopup },
    data() {
        return {
            professionNumber: Math.floor(Math.random() * (300 - 250 + 1)) + 250,
            outerRadius: 105,
            innerRadius: 20,
            gap: 5,
            categories: [],
            showPopup: false,
            showScroll: true,
        };
    },
    completeStep() {
        this.$apollo
            .mutate({
                mutation: gql`
                    ${onBoardingCompleteStepMutation}
                `,
                variables: {
                    step: 11,
                    result: 1,
                },
            })
            .then(() => {
                this.showPopup = false;
                this.redirectController();
            });
    },
    created() {
        this.getTalents();
    },
    methods: {
        declination,
        openPopup(status) {
            this.showPopup = status;
        },
        completeStep() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 11,
                        result: 1,
                    },
                })
                .then(() => {
                    this.redirectController();
                });
        },
        async getTalents() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 11,
                    },
                })
                .then((r) => {
                    this.categories.push(...r.data.onBoardingGetStep.test);
                });
        },
    },
};
</script>

<style lang="scss">
.welcome-talents {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    @media (max-width: 767px) {
        position: initial;
    }

    &.hidden {
        max-height: 100vh;
        overflow: hidden;
    }

    &__illustration {
        position: absolute;
        top: calc(50% - 440px - 25px);
        left: calc(50% - 440px);
        width: 880px;
        height: 880px;

        @media (max-width: 767px) {
            position: absolute;
            top: calc(50% - 310px + 25px);
            left: calc(50% - 310px);
            width: 620px;
            height: 620px;
        }
    }

    &__text-box {
        pointer-events: none;
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 7px !important;
        line-height: 1.2 !important;
        color: rgba(#fff, 0.2);

        &.current {
            color: #222;
        }
    }

    &__pie-section {
        fill: rgba(#fff, 0.03);
        transition: 0.4s;

        &:hover {
            fill: rgba(#fff, 0.05);
        }

        &.current {
            fill: $gold;

            &:hover {
                cursor: pointer;
                fill: darken($gold, 5%);
            }
        }
    }

    &__unlocked {
        width: 16 * 0.75px;
        height: 23 * 0.75px;
        fill: rgba(#fff, 0.1);
    }

    &__popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        max-width: 700px;
        width: 100%;
        padding: 30px 50px;
        background: #222;
        border: 1px solid $gold;
        z-index: 10;

        @media (max-width: 480px) {
            padding: 20px;
        }

        &__popup-wrap {
            position: relative;
            margin: 30px 0;
        }

        &__popup-title {
            font-size: 18px;
            text-align: center;
            color: $gold;
            text-transform: uppercase;
        }

        &__popup-list {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-height: 50vh;
            width: 100%;
            overflow: hidden;

            .ps__rail-y {
                opacity: 0.5 !important;
            }
        }

        &__popup-item {
            width: 480px;
            max-width: 100%;
            text-align: left;
            font-size: 16px;

            &:not(:first-child) {
                margin-top: 10px;
            }
        }

        &__popup-item-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 20px;
            flex: none;
        }

        &__popup-item-text {
            flex-grow: 1;
        }

        &__popup-btn {
            margin-top: 10px;
        }

        &__scroll-icon {
            position: absolute;
            bottom: 0;
            right: -3px;
            fill: $gold !important;
            animation: scrollDown 1s infinite;
        }
    }
}

@keyframes scrollDown {
    0% {
        bottom: 10px;
    }
    50% {
        bottom: 0;
    }
    100% {
        bottom: 10px;
    }
}
</style>
