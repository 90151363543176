<template lang="pug">
    welcome-info
        template(v-slot:title) {{ title }}
        template(v-slot:subtitle )
            span
            .welcome-business-card-info__error(v-if="error") {{ error }}

        template(v-slot:content)
            .welcome-business-card-info
                transition(name="fadeIn")
                    loading(v-if="isLoading")._loading
                template(v-if="!isLoading")
                    component(:is="settingsComponent" :person="personProfile" @update="completeStep(10)" :required="required" :showCtrl="false")._settings
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import ChangeName from "@/components/person/change-my-name/change-my-name";
import UiButton from "@/components/ui/ui-button/ui-button";
import Multiselect from "vue-multiselect/src";

//import gql from "graphql-tag";
import UiCheckbox from "@/components/ui/ui-checkbox/ui-checkbox";
import AboutSettingsForm from "@/components/person/settings/about-form/about-form";
import gql from "graphql-tag";
import personProfileQuery from "@/graphql/queries/personProfile.query.graphql";
import CareerSettingsForm from "@/components/person/settings/career-form/career-form";
import EducationSettingsForm from "@/components/person/settings/education-form/education-form";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";

export default {
    name: "WelcomeBusinessCardInfo",
    components: {
        EducationSettingsForm,
        CareerSettingsForm,
        AboutSettingsForm,
        UiCheckbox,
        UiButton,
        ChangeName,
        WelcomeInfo,
        Multiselect,
    },
    data() {
        return {
            isLoading: true,
            completed: [],
            personProfile: null,
            error: "",
            required: false,
        };
    },
    computed: {
        settingsComponent: function () {
            if (!this.completed.length) {
                return "about-settings-form";
            }
            if (this.completed.length === 1) {
                return "education-settings-form";
            }
            return "career-settings-form";
        },
        title: function () {
            if (this.settingsComponent === "about-settings-form") {
                return this.locale("info-title");
            }
            if (this.settingsComponent === "education-settings-form") {
                return this.locale("edu-title");
            }
            return this.locale("career-title");
        },
    },
    validations: {},
    async mounted() {
        await this.getPersonProfile();
        this.completed = await this.getOnBoardingCompletedSteps();
        this.completed = this.completed.filter((s) => s.step_number === 10);
        this.isLoading = false;
    },
    methods: {
        async getPersonProfile() {
            await this.$apollo
                .query({
                    query: gql(personProfileQuery),
                    variables: {
                        id: this.myPerson.uuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.personProfile = r.data.personProfile;
                });
        },
        async completeStep(step) {
            this.errro = "";
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: step,
                        result: 1,
                    },
                })
                .then(async () => {
                    this.error = "";
                    this.required = false;
                    this.completed = await this.getOnBoardingCompletedSteps();
                    this.completed = this.completed.filter(
                        (s) => s.step_number === 10,
                    );
                })
                .catch(() => {
                    if (this.completed.length === 0) {
                        this.error = this.locale("all-fields-error");
                    }
                    if (this.completed.length === 1) {
                        this.required = true;
                        this.error = this.locale("edu-fields-error");
                    }
                    if (this.completed.length === 2) {
                        this.required = true;
                        this.error = this.locale("career-fields-error");
                    }
                })
                .finally(async () => {
                    if (this.completed.length === 3 && step === 10) {
                        await this.completeStep(11);
                        await this.$router.push(
                            "/person/" + this.myPerson.uuid,
                        );
                    }
                });
        },
    },
};
</script>


<style lang="scss">
.welcome-business-card-info {
    position: relative;
    width: 320px;

    .loading {
        position: absolute;
        opacity: 0.8;
    }

    &__btn {
        width: 100%;
        margin-top: 30px;
    }

    &__settings {
        text-align: left;
    }

    &__error {
        color: #d42020;
    }
}
</style>
