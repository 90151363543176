<template lang="pug">
    welcome-info
        template(v-slot:title)
            span(v-html="title")
        template(v-slot:subtitle)
            span(v-html="subtitle")
        template(v-slot:content)
            .welcome-profession(ref="box")
                ._profession-name(v-for="(category, index) in currentData" v-if="disabledCategoriesCount < 3" :key="index" ref="name" :style="{opacity: showName ? 1 : 0}" @click="chooseCategory(category)" :class="{disabled: category.disabled, active: isReady}") {{ category.name }}
                transition(name="fade")
                    svg(x="0px" y="0px" viewBox="0 0 662.1 605" style="enable-background:new 0 0 662.1 605;" xml:space="preserve" v-if="categories.length && disabledCategoriesCount < 3")._illustration
                        g._brain
                            path(class="st0" d="M289.6,289.5l17,23.7c12.9,1.9,39,5.1,52,6.4l-13.5-9l23.3-11.7l-26.3-6.8l2.2-24.5l-12.3,10.5l-15.4-11.3l-8.3,18.8L289.6,289.5z")
                            polygon(class="st0" points="346.1,333.6 339.7,338.5 332.2,316.6 316.3,320.4 306.5,313.2 291.1,307.6 289.6,289.8 296,278.5 316.3,266.8 343.9,267.6 362,281.5 368.4,298.9 360.4,309.8 358.6,319.6 345.4,323 	")
                            polyline(class="st0" points="295.2,277.5 307.2,285.5 340,290.8 330.6,278.4 317.7,301.8 331.4,315.5 340.4,291.2 344.4,309.5 361.1,281 330.6,278 306.8,285.9 305.8,312.4 317.7,301.4 288.7,288.6 289.6,289.5 318.6,302.3 306.7,313.3 307.7,286.8 331.4,278.9 362,281.9 345.2,310.4 341.2,292.1 332.3,316.4 318.6,302.7 331.4,279.3 340.8,291.7 308,286.5 296.1,278.4 317.7,302.1 315.5,266 316.4,266.9 318.6,303 344.5,309.7 317.7,302.1 361.1,280.6 362,281.5 318.6,303 345.4,310.6 344.5,322.1 331.2,315.3 344.5,309.7 359.6,308.8 360.5,309.7 345.4,310.6 332.1,316.2 345.4,323 	")
                            path(class="st0" d="M288.7,288.6l17,23.7c12.9,1.9,39,5.1,52,6.4l-13.5-9l23.3-11.7l-26.3-6.8l2.2-24.5l-12.3,10.5L315.5,266l-8.3,18.8L288.7,288.6z")
                            polygon(class="st0" points="345.3,332.7 338.8,337.6 331.3,315.7 315.5,319.5 305.7,312.3 290.2,306.7 288.7,288.9 295.1,277.6 315.5,265.9 343,266.7 361.1,280.6 367.5,298 359.6,308.9 357.7,318.7 344.5,322.1 	")
                        path._circle(d="M331.7,214.7c48.3,0,87.5,39.2,87.5,87.5s-39.2,87.5-87.5,87.5c-48.3,0-87.5-39.2-87.5-87.5S283.4,214.7,331.7,214.7z")
                        g._blade(v-for="(category, index) in currentData" :key="category.id" :style="`transform: rotateZ(${360 / currentDataLength * index}deg);}`" @click="chooseCategory(category)" :data-opacity-level="profession ? category.value : 6" :class="{disabled: category.disabled}")
                            path._blade-line(d="M108.5,133c0,0,22.9,24.8,60.3,48.6c30.4,19.3,53.1,55.4,93.6,66.7")
                            path._blade-line(d="M108.5,133c0,0,9.3,12,39.1,39.2c24.9,22.7,74.3,64.8,114.8,76.1")
                            path._blade-line(d="M108.5,133c0,0,24.1,12.8,61.5,36.6c30.4,19.3,52,67.4,92.5,78.7")
                            path._blade-line(d="M108.5,133c0,0,77.3,19,73.2,44.8c-5,31,38.3,59.4,80.7,70.5")
                            path._blade-line(d="M108.5,133c0,0,14.1,2.2,54.2,27.3c42.8,26.8,54.6,70.7,99.7,88")
                            path._blade-line(d="M108.5,133c0,0,30.1,20.4,60.3,48.6c34.4,32.1,53.1,55.4,93.6,66.7")
                            path._blade-line(d="M108.5,133c0,0,32.7,25.1,66.7,33.5c32.2,7.9,52.2,66.6,87.2,81.8")
                            path._blade-line(d="M108.5,133c0,0,2.9,3.5,43.1,28.7c42.8,26.8,70.3,75.3,110.8,86.6")
                            path._blade-line(d="M108.5,133c0,0,43.7,16,83.8,41.1c42.8,26.8,29.6,62.9,70.1,74.2")
                            path._blade-line(d="M108.5,133c0,0,12,20,56,36.6c50.3,19,62.8,63.5,97.9,78.7")
                            ellipse._blade-dot(transform="matrix(0.6235 -0.7818 0.7818 0.6235 -62.6971 134.9862)" cx="108.8" cy="132.6" rx="8" ry="8" :data-deg="360 / currentDataLength * index" ref="name-position")
                            ellipse._blade-dot.-for-circle(transform="matrix(0.6235 -0.7818 0.7818 0.6235 -96.0124 297.8574)" cx="261.2" cy="248.6" rx="6" ry="6")
                ._prof-list(v-if="disabledCategoriesCount >= 3")
                    ui-button._prof-item(v-for="(category, index) in currentData" :key="index" :text="category.name" @click.native="chooseCategory(category)")
                transition(name="fade")
                    ._footer(v-if="profession")
                        ._footer-text(v-if="disabledCategoriesCount >= 3" v-html="locale('merlin-help')")
                        ui-button(:text="disabledCategoriesCount >= 3 ? locale('more-btn') : locale('next-btn')" @click.native="nextStep()" :disabled="!availableNextBtn" :class="{pulse: availableNextBtn}")._next-step-btn
                        // ui-button(text="Назад" @click.native="profession = ''" type="bordered" color="gray")._next-step-btn
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import UiButton from "@/components/ui/ui-button/ui-button";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";

export default {
    name: "WelcomeProfessions",
    components: { UiButton, WelcomeInfo },
    data() {
        return {
            categories: [],
            profession: "",
            professionCounter: 0,
            showPopup: false,
            stage: 1,
            showPopupHelps: true,
            showProfessionHelp: false,
            showName: true,
            sendReady: false,
            isReady: false,
        };
    },
    computed: {
        currentData: function () {
            if (this.disabledCategoriesCount >= 3) {
                return this.categories.map((category) => {
                    return {
                        name: category.professions.find(
                            (profession) => !profession.disabled,
                        ).name,
                    };
                });
            }
            if (this.profession) {
                return this.categories.find(
                    (cat) => cat.name === this.profession,
                )?.professions;
            }
            return this.categories;
        },
        currentDataLength: function () {
            return this.profession
                ? Object.keys(this.currentData).length
                : this.currentData.length;
        },
        title: function () {
            if (this.profession) {
                if (this.disabledCategoriesCount >= 3) {
                    return this.locale("title-1");
                }
                let text = this.locale("these");
                if (this.disabledCategoriesCount === 1) {
                    text = this.locale("title-2");
                }
                if (this.disabledCategoriesCount === 2) {
                    text = this.locale("title-3");
                }
                return `${text} ${this.locale("title-helper")} "${
                    this.profession
                }"`;
            }
            return this.locale("title");
        },
        subtitle: function () {
            if (this.profession) {
                if (this.disabledCategoriesCount >= 3) {
                    return "";
                } else {
                    return this.locale("subtitle-1");
                }
            }
            return this.locale("subtitle-2");
        },
        availableNextBtn: (state) =>
            state.currentData.filter((item) => item.disabled).length >= 2 ||
            state.disabledCategoriesCount >= 3,
        disabledCategoriesCount: (state) =>
            state.categories.filter((item) => item.disabled).length,
    },
    watch: {
        async profession() {
            this.showName = false;
            this.professionCounter++;
            setTimeout(async () => {
                await this.formatTitles();
                this.showName = true;
            }, 500);
        },
    },
    created() {
        window.addEventListener("resize", async () => {
            this.formatTitles();
        });
        setInterval(() => {
            this.formatTitles();
        }, 1000);
    },
    async mounted() {
        if (this.$metrika?.reachGoal) {
            this.$metrika.reachGoal("professions_test_end");
        }
        await this.getProfessions();
        this.$nextTick(() => {
            setTimeout(() => {
                this.formatTitles();
            }, 500);
        });
    },
    methods: {
        async getProfessions() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 12,
                    },
                })
                .then((r) => {
                    const result = [];
                    const stats = r.data.onBoardingGetStep.stats;

                    console.log(r);


                    Object.keys(stats).map((categoryName) => {
                        const professions = [];
                        let sum = 0;
                        Object.keys(stats[categoryName]).forEach(
                            (professionName) => {
                                professions.push({
                                    name: professionName,
                                    value: stats[categoryName][professionName],
                                    disabled: false,
                                });
                                sum += stats[categoryName][professionName];
                            },
                        );
                        result.push({
                            name: categoryName,
                            professions: professions
                                .sort((a, b) => (a.value < b.value ? 1 : -1))
                                .slice(0, 3),
                            average: sum,
                            sum,
                            disabled: false,
                        });
                    });
                    this.isActive = true;
                    this.categories = result
                        .sort((a, b) => (a.average < b.average ? 1 : -1))
                        .slice(0, 3);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        async chooseCategory(category) {
            if (this.disabledCategoriesCount === 3) {
                await this.showProfessionReferralModal(category.name);
                return;
            }
            this.yandexGoal(`referel_${this.disabledCategoriesCount + 1}`);
            if (!this.profession) {
                this.profession = category.name;
            } else {
                this.currentData.forEach((profession) => {
                    profession.disabled = true;
                });
                category.disabled = false;
            }
        },
        formatTitles() {
            this.isReady = false;
            const positions = this.$refs["name-position"];
            const boxPosition = document.body.getBoundingClientRect();
            console.log(positions);
            positions.forEach((position, index) => {
                const textPosition = position.getBoundingClientRect();
                const text = this.$refs.name[index];
                text.style.left = `${
                    textPosition.left - text.clientWidth / 2
                }px`;
                if (Number(position.getAttribute("data-deg")) > 140) {
                    text.style.top = `${
                        textPosition.bottom + 5 - boxPosition.top
                    }px`;
                } else {
                    if (text.clientHeight > 80) {
                        text.style.top = `${
                            textPosition.top -
                            60 -
                            boxPosition.top -
                            text.clientHeight / 2
                        }px`;
                    } else if (text.clientHeight > 50) {
                        text.style.top = `${
                            textPosition.top -
                            35 -
                            boxPosition.top -
                            text.clientHeight / 2
                        }px`;
                    } else if (text.clientHeight > 30) {
                        text.style.top = `${
                            textPosition.top -
                            25 -
                            boxPosition.top -
                            text.clientHeight / 2
                        }px`;
                    } else {
                        text.style.top = `${
                            textPosition.top - 25 - boxPosition.top
                        }px`;
                    }
                }
            });
            this.isReady = true;
        },
        nextStep() {
            this.categories.find(
                (item) => item.name === this.profession,
            ).disabled = true;
            if (this.disabledCategoriesCount < 3) {
                this.profession = this.categories.find(
                    (item) => !item.disabled,
                ).name;
            }
            if (this.disabledCategoriesCount === 3) {
                if (this.sendReady) {
                    if (this.$metrika?.reachGoal) {
                        this.$metrika.reachGoal("next");
                    }
                    this.$apollo
                        .mutate({
                            mutation: gql`
                                ${onBoardingCompleteStepMutation}
                            `,
                            variables: {
                                step: 12,
                                result: 1,
                            },
                        })
                        .then(() => {
                            this.$router.push("/welcome/subscribe");
                        })
                        .catch(() => {
                            this.$router.push("/welcome/subscribe");
                        });
                }
                this.sendReady = true;
            }
        },
    },
};
</script>
<style lang="scss">
.welcome-profession {
    $root: &;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    @media (max-width: 767px) {
        min-height: 400px;
        margin-bottom: 40px;
        align-items: flex-start;
    }
    @media (max-width: 450px) {
        align-items: center;
        min-height: 365px;
    }

    &__profession-name {
        font-size: 16px;
        position: absolute;
        max-width: 200px;
        transition: opacity 0.4s;
        opacity: 0 !important;
        z-index: 10;
        animation: pulseText 1s infinite;

        &.active {
            opacity: 1 !important;
            transition: none;
        }

        &:hover {
            cursor: pointer;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            color: $gold;
            font-size: 18px;
            @media (max-width: 600px) {
                font-size: 13px;
            }
        }

        @media (max-width: 600px) {
            font-size: 13px;
            max-width: 100px;
            white-space: initial;
        }

        &.disabled {
            filter: grayscale(1);
            opacity: 0.2 !important;
        }
    }

    &__illustration {
        width: 520px;
        height: 520px;
        @media (max-width: 767px) {
            width: 400px;
            height: 400px;
        }
        @media (max-width: 450px) {
            width: 300px;
            height: 300px;
        }
    }

    &__brain {
        fill: none;
        stroke: $gold;
    }

    &__circle {
        fill: none;
        stroke: $gold;
    }

    &__blade {
        transform-origin: 50% 50%;
        transition: 0.4s;

        &:hover {
            cursor: pointer;
        }

        @for $i from 3 through 20 {
            &:nth-child(#{$i}) {
                #{$root} {
                    @if $i == 3 {
                        &__blade-line {
                            @for $j from 1 through 10 {
                                &:nth-child(#{$j}) {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @if $i == 4 {
                        &__blade-line {
                            @for $j from 1 through 7 {
                                &:nth-child(#{$j}) {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @if $i == 5 {
                        &__blade-line {
                            @for $j from 1 through 4 {
                                &:nth-child(#{$j}) {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @if $i > 5 AND $i < 10 {
                        &__blade-line {
                            @for $j from 1 through 3 {
                                &:nth-child(#{$j}) {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @if $i >= 10 AND $i < 15 {
                        &__blade-line {
                            @for $j from 1 through 2 {
                                &:nth-child(#{$j}) {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @if $i >= 15 {
                        &__blade-line {
                            @for $j from 1 through 1 {
                                &:nth-child(#{$j}) {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }

        &[data-opacity-level="1"] {
            opacity: 0.16;
        }

        &[data-opacity-level="2"] {
            opacity: 0.32;
        }

        &[data-opacity-level="3"] {
            opacity: 0.48;
        }

        &[data-opacity-level="4"] {
            opacity: 0.64;
        }

        &[data-opacity-level="5"] {
            opacity: 0.8;
        }

        &[data-opacity-level="6"] {
            opacity: 1;
        }

        &.disabled {
            filter: grayscale(1);
            opacity: 0.2 !important;
        }
    }

    &__blade-line {
        visibility: hidden;
        fill: transparent;
        stroke: $gold;
    }

    &__blade-dot {
        fill: $gold;

        &_for-circle {
            fill: $gold;
        }
    }

    &__blade-text {
        font-size: 10px;
        fill: #fff;
    }

    &__text-box {
        pointer-events: none;
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 7px !important;
        line-height: 1.2 !important;

        &.current {
            color: #222;
        }
    }

    &__pie-section {
        fill: rgba(#fff, 0.03);
        transition: 0.4s;

        &:hover {
            fill: rgba(#fff, 0.05);
        }

        &.current {
            fill: $gold;

            &:hover {
                cursor: pointer;
                fill: darken($gold, 5%);
            }
        }
    }

    &__unlocked {
        width: 16 * 0.75px;
        height: 23 * 0.75px;
        fill: rgba(#fff, 0.1);
    }

    &__next-step-btn {
        &:not(:first-child) {
            margin-left: 15px;
        }

        &.pulse {
            animation: goldPulse 1s infinite;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 80px;
        @media (max-width: 767px) {
            padding: 0 20px;
            width: 100%;
        }
    }

    &__footer-text {
        font-size: 14px;
        margin-bottom: 10px;
    }

    &__prof-item {
        width: 100%;
        max-width: 320px;
        animation: goldPulse 2s infinite;

        &:not(:first-child) {
            margin-top: 25px;
        }
    }
}

@keyframes pulseText {
    0% {
        text-shadow: 0 0 2px $gold;
        transform: scale(1);
    }
    50% {
        text-shadow: 0 0 10px $gold;
        transform: scale(1.1);
    }
    100% {
        text-shadow: 0 0 2px $gold;
        transform: scale(1);
    }
}
</style>
