<template lang="pug">
    welcome-info
        template(v-slot:title) {{ locale("title") }} {{ myUser.display_name }}
        template(v-slot:subtitle) {{ locale("subtitle") }}
        template(v-slot:content)
            .welcome-personal-info
                transition(name="fadeIn")
                    loading(v-if="isLoading")._loading
                ui-input(
                    :label="locale('age')"
                    type="number"
                    :placeholder="locale('age-placeholder')"
                    v-bind:value.sync="age"
                    :error="locale('age-error')"
                    :validator="$v.age.$error && $v.age.$dirty"
                )._input
                multiselect._input(v-model="country" :options="countries" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('country-placeholder')")
                multiselect._input(v-model="city" :disabled="!country.id" :options="cities" :searchable="true" :loading="isCityLoading" @search-change="searchCity" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('city-placeholder')")
                    template(v-slot:noOptions) {{ locale("city-no-options")
                //._error(v-if="$v.cityId.$error && $v.cityId.$dirty") Выберите город {{ cityId }}
                ._group
                    ._group-title {{ locale("social-title") }}
                    ui-checkbox(v-for="social in socialList" :id="social.id" :key="social.id" :label="social.value" type="radio" name="status" v-bind:model.sync="socialStatus")._checkbox
                    ._error(v-if="$v.socialStatus.$error && $v.socialStatus.$dirty") {{ locale("social-error") }}
                ui-button(:text="locale('next-btn')" @click.native="saveAge()")._btn
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import ChangeName from "@/components/person/change-my-name/change-my-name";
import {between, required} from "vuelidate/lib/validators";
import UiButton from "@/components/ui/ui-button/ui-button";
import Multiselect from "vue-multiselect/src";

import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import getCountriesListQuery from "@/graphql/queries/getCountriesList.query.graphql";
import getCitiesListQuery from "@/graphql/queries/getCitiesList.query.graphql";
import UiCheckbox from "@/components/ui/ui-checkbox/ui-checkbox";

export default {
    name: "WelcomePersonInfo",
    components: {UiCheckbox, UiButton, ChangeName, WelcomeInfo, Multiselect},
    data() {
        return {
            isLoading: true,
            age: null,
            country: {
                name: "",
            },
            countries: [],
            isCityLoading: false,
            cities: [],
            city: {
                name: "",
                id: 0,
            },
            cityTimeout: null,
            socialList: [],
            socialStatus: null,
        };
    },
    computed: {
        cityId: (state) => state.city.id,
    },
    validations: {
        age: {
            required,
            between: between(14, 99),
        },
        socialStatus: {
            required,
            between: between(1, 99),
        },
    },
    watch: {
        country: {
            deep: true,
            handler() {
                this.searchCity("");
            },
        },
    },
    async mounted() {
        this.country.name = this.locale("choose-country");
        this.city.name = this.locale("choose-city");
        await this.getSocialStatusList();
        await this.getCountries();
        this.isLoading = false;
    },
    methods: {
        async saveAge() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.isLoading = true;
            await this.confirmAge();
            setTimeout(async () => {
                await this.confirmCity();
            }, 1000);
            setTimeout(async () => {
                await this.confirmSocialStatus();
            }, 2000);
            setTimeout(async () => {
                this.yandexGoal("psychotype_personal_data");
                await this.redirectController();
            }, 3000);
        },
        confirmAge() {
            if (!this.age) return;
            this.$apollo.mutate({
                mutation: gql`
                    ${onBoardingCompleteStepMutation}
                `,
                variables: {
                    step: 2,
                    result: this.age,
                },
            });
        },
        confirmCity() {
            this.$apollo.mutate({
                mutation: gql`
                    ${onBoardingCompleteStepMutation}
                `,
                variables: {
                    step: 3,
                    result: this.cityId,
                },
            });
        },
        confirmSocialStatus() {
            if (!this.socialStatus) return;
            this.$apollo.mutate({
                mutation: gql`
                    ${onBoardingCompleteStepMutation}
                `,
                variables: {
                    step: 4,
                    result: this.socialStatus,
                },
            });
        },
        async getCountries() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getCountriesListQuery}
                    `,
                })
                .then((r) => {
                    this.countries.push(...r.data.getCountriesList);
                });
        },
        searchCity(query) {
            if (!this.country.id) return;
            this.isCityLoading = true;
            clearTimeout(this.cityTimeout);
            this.cityTimeout = setTimeout(() => {
                this.$apollo
                    .query({
                        query: gql`
                            ${getCitiesListQuery}
                        `,
                        variables: {
                            countryId: Number(this.country.id),
                            term: query,
                        },
                    })
                    .then((r) => {
                        this.cities = r.data.getCitiesList;
                        this.isCityLoading = false;
                    });
            }, 500);
        },
        async getSocialStatusList() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 4,
                    },
                })
                .then((r) => {
                    const list = r.data.onBoardingGetStep.poll.values;

                    list.forEach((item) => {
                        this.socialList.push({
                            ...item,
                        });
                    });
                }).catch(() => {
                    this.$router.push("/messages");
                });
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.welcome-personal-info {
    position: relative;

    .loading {
        background: #000;
    }

    &__input {
        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    &__btn {
        width: 100%;
        margin-top: 30px;
    }

    &__group {
        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    &__group-title {
        width: 100%;
        text-align: left;
        margin-bottom: 16px;
        font-size: 14px;
        color: #959595;
    }

    &__checkbox {
        display: flex;
        width: 100%;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &__error {
        width: 100%;
        text-align: left;
        margin-top: 12px;
        color: #b54848;
        font-size: 12px;
    }

    .multiselect--disabled {
        background: transparent;
        opacity: 0.2;
    }

    .multiselect--disabled .multiselect__current,
    .multiselect--disabled .multiselect__select {
        background: transparent;
    }

    .multiselect__tags {
        background: none;
        border: 1px solid #434343;
        border-radius: 0;
    }

    .multiselect__input,
    .multiselect__single {
        background: none;
        color: #bcbcbc;
        font-size: 14px;
        margin-bottom: 0;

        &::-webkit-input-placeholder {
            /* Chrome */
            color: #434343;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: #434343;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #434343;
            opacity: 1;
        }

        &:-moz-placeholder {
            /* Firefox 4 - 18 */
            color: #434343;
            opacity: 1;
        }
    }

    .multiselect__content-wrapper {
        background: #222;
        border: 1px solid #434343;
        border-radius: 0;
    }

    .multiselect__option {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
        white-space: break-spaces;
        padding: 7px 11px;
        min-height: 30px;

        &--selected,
        &--highlight {
            background: $gold;
            color: #222;
        }
    }

    .multiselect__spinner {
        background: #222;
    }
}
</style>
