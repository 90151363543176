<template lang="pug">
    #analyze-ready-page
        loading(v-if="isLoading").loader
        welcome-info
            template(v-slot:title) {{ locale("title") }}
            template(v-slot:subtitle) {{ locale("subtitle") }}
            template(v-slot:content)
                .welcome-analyze-ready
                    span(v-html="locale('desc')")
                    ui-button(:text="locale('start-btn')" @click.native="completeStep()").welcome-analyze-ready__btn
                    blockquote._quote {{ locale("quote") }}
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import UiButton from "@/components/ui/ui-button/ui-button";
export default {
    name: "WelcomeAnalyzeReady",
    components: { UiButton, WelcomeInfo },
    data() {
        return {
            memberCount: 384983,
            isLoading: true,
        };
    },
    async mounted() {
        this.yandexGoal("analyze_ready");
        await this.$store.dispatch("set", {
            name: "onboardingCtrlStepNumber",
            value: 4,
        });
        await this.loadMyUser(false);
        const photoSkipper = localStorage.getItem("photo-skipped");
        if (!photoSkipper) {
            return this.$router.push("/welcome/anfas");
        } else {
            this.isLoading = false;
        }
        let date = new Date().getTime() + "";
        this.memberCount += +date.substring(7, date.length - 1);
        this.memberCount = this.thousandSeparator(this.memberCount);
    },
    methods: {
        completeStep() {
            this.$router.push("/welcome/profession-vote");
        },
        thousandSeparator(str) {
            const parts = (str + "").split(".");
            const main = parts[0];
            const len = main.length;
            let output = "";
            let i = len - 1;

            while (i >= 0) {
                output = main.charAt(i) + output;
                if ((len - i) % 3 === 0 && i > 0) {
                    output = " " + output;
                }
                --i;
            }

            if (parts.length > 1) {
                output += "." + parts[1];
            }
            return output;
        },
    },
};
</script>
<style lang="scss">
.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.welcome-analyze-ready {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    &__number {
        font-size: 20px;
        color: $gold;
    }
    &__btn {
        margin-top: 60px;
        max-width: 320px;
    }
    &__quote {
        font-size: 16px;
        color: $gold;
        margin-top: 40px;
        opacity: 0.7;
    }
}
</style>
