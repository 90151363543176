<template lang="pug">
    .welcome-info(:class="type")
        h2._title(:class="{headType}")
            slot(name="title")
        h3._subtitle
            slot(name="subtitle")
        ._content
            ._list(v-if="list.length")
                ._list-item(v-for="item in list" :key="item.id")
                    ._list-item-icon-box
                        svg-icon(:icon-name="item.icon" added-catalog="welcome" :class="item.icon")._list-item-icon
                    ._list-item-text {{ item.text }}
            slot(name="content")
        ui-button(v-if="link" :text="linkText" @click.native="redirect")
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
export default {
    name: "WelcomeInfo",
    components: { UiButton },
    props: {
        type: {
            type: String,
            default: "",
            required: false
        },
        headType: {
            type: String,
            default: "",
            required: false
        },
        link: {
            type: String,
            default: "",
            required: false,
        },
        linkText: {
            type: String,
            default: "Продолжить",
            required: false,
        },
        list: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    methods: {
        redirect() {
            this.$router.push(this.link);
        },
    },
};
</script>

<style src="./welcome-info.scss" lang="scss"></style>
