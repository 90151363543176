<template lang="pug">
  welcome-info(link="/welcome/name")
    template(v-slot:title) Я смогу Вам помочь.
    template(v-slot:content) Я изучил более 1150 профессий из 123 сфер деятельности.
      br/br/ Сейчас мы подберём лучшие для вас.
      br/br/ Нужно выполнить первое задание.
      br/br/ Это займёт несколько минут.
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
export default {
    name: "WelcomeStart",
    components: { WelcomeInfo },
    data() {
        return {};
    },
};
</script>
