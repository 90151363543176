<template lang="pug">
  welcome-info(link="/welcome/talents" link-text="Посмотреть")
    template(v-slot:title) Внимание. 
      br/ Я проанализировал Ваши ответы
    template(v-slot:content) Получилось определить Ваши сильные и слабые стороны.
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
export default {
    name: "WelcomeTalentsReady",
    components: { WelcomeInfo },
    data() {
        return {};
    },
};
</script>
