<template lang="pug">
    welcome-info.welcome-profession-vote-header
        template(v-slot:title)
            span(v-html="headText.title")
        template(v-slot:subtitle)
            span(v-html="headText.subtitle")._with-arrow
        template(v-slot:content)
            .welcome-profession-vote
                transition(name="fade")
                    ._illustration-box
                        layout-decor(
                            :showBigCircle="true"
                            :showMediumCircle="true"
                            :showSmallCircle="true"
                            :showOuterWave="true"
                            :showInnerLineWave="true"
                            :showInnerWave="true"
                        )._decor
                        svg(viewBox="-205 -205 410 410" v-if="categories.length")._illustration
                            g(v-for="(sectionPath, sectionIndex) in sectionPaths" :key="sectionIndex")._pie-group
                                path(:d="sectionPath.d" :class="{current: categories[sectionIndex].current}" @click="openPopup(categories[sectionIndex].current)")._pie-section
                                svg(width="60px" height="50px" :x="sectionPath.cx" :y="sectionPath.cy" v-if="categories[sectionIndex].unlocked")
                                    foreignObject(width="100%" height="30")._text-box
                                        p._text(:class="{current: categories[sectionIndex].current}")
                                            span(v-for="(text, index) in categories[sectionIndex].name.split(\" и \")" :key="index") {{ index > 0 ? `и ${text}` : text }}
                                svg(:x="sectionPath.cx + 24" :y="sectionPath.cy - 5" width="11" height="16" viewBox="0 0 16 23" v-else)._unlocked
                                    path(d="M14.347,22.629 L1.652,22.629 C0.919,22.629 0.324,22.10 0.324,21.248 L0.324,10.533 C0.324,9.770 0.919,9.152 1.652,9.152 L2.449,9.152 L2.449,6.170 C2.449,2.967 4.946,0.370 8.26,0.370 C11.106,0.370 13.603,2.967 13.603,6.170 L13.603,9.152 L14.347,9.152 C15.80,9.152 15.675,9.770 15.675,10.533 L15.675,21.248 C15.675,22.10 15.80,22.629 14.347,22.629 ZM12.541,6.252 C12.541,3.675 10.531,1.585 8.53,1.585 C5.574,1.585 3.564,3.675 3.564,6.252 L3.564,9.152 L12.541,9.152 L12.541,6.252 ZM14.612,10.919 C14.612,10.553 14.327,10.257 13.975,10.257 L2.24,10.257 C1.672,10.257 1.386,10.553 1.386,10.919 L1.386,20.861 C1.386,21.227 1.672,21.524 2.24,21.524 L13.975,21.524 C14.327,21.524 14.612,21.227 14.612,20.861 L14.612,10.919 ZM9.141,16.968 L9.141,18.403 C9.141,19.59 8.630,19.591 7.999,19.591 C7.369,19.591 6.857,19.59 6.857,18.403 L6.857,16.937 C6.189,16.522 5.742,15.764 5.742,14.896 C5.742,13.585 6.764,12.521 8.26,12.521 C9.287,12.521 10.310,13.585 10.310,14.896 C10.310,15.786 9.839,16.562 9.141,16.968 Z")
            transition(name="fadeIn")
                profession-vote-popup(v-if="showPopup" :profession="currentProfession" :stage="stage" :helps="showPopupHelps" :completedCount="completedCount" @close="closePopup" @getNewProfession="getProfessions")
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import declination from "@/utils/declination";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import OnboardDescriptionPopup from "@/components/app/onboard-description-popup/onboard-description-popup";
import UiButton from "@/components/ui/ui-button/ui-button";
import ProfessionVotePopup from "@/components/welcome/profession-vote-popup/profession-vote-popup";
import LayoutDecor from "@/components/ui/layout-decor/layout-decor";

export default {
    name: "WelcomeProfessionVote",
    components: {
        LayoutDecor,
        ProfessionVotePopup,
        UiButton,
        WelcomeInfo,
        OnboardDescriptionPopup,
    },
    data() {
        return {
            outerRadius: 105,
            innerRadius: 20,
            gap: 5,
            categories: [],
            showPopup: false,
            stage: 1,
            showPopupHelps: true,
            showProfessionHelp: false,
            lastVoteIndex: 1,
            completedCount: 0,
        };
    },
    computed: {
        sectionPaths() {
            const step = (2 * Math.PI) / this.categories.length;
            return this.getPaths(this.categories, step);
        },
        currentProfession: (state) =>
            state.categories.find((item) => item.current),
        headText: function () {
            const text = {
                title: "",
                subtitle: "",
            };

            const professionsCount = Math.round(1150 / this.categories.length);

            if (this.completedCount === 1) {
                const max = 980;
                const min = 980;
                const professionNumber = this.generateProfessionCount(min, max);
                const decl = declination(professionNumber, [
                    this.locale("profession-declination-1"),
                    this.locale("profession-declination-2"),
                    this.locale("profession-declination-3"),
                ]);
                text.title = this.locale("title-1");
                text.subtitle = this.locale("subtitle-1", {
                    count: professionNumber,
                    decl: decl
                });
            }
            if (this.completedCount === 2) {
                const max = professionsCount * (this.categories.length * 0.65);
                const min = Math.trunc(max * 0.75);
                const professionNumber = this.generateProfessionCount(min, max);
                const decl = declination(professionNumber, [
                    this.locale("profession-declination-1"),
                    this.locale("profession-declination-2"),
                    this.locale("profession-declination-3"),
                ]);
                text.title = this.locale("title-2", {
                    count: professionNumber,
                    decl: decl
                });
                text.subtitle = this.locale("subtitle-2");
            }
            if (this.completedCount === 3) {
                const max = professionsCount * (this.categories.length * 0.45);
                const min = Math.trunc(max * 0.75);
                const professionNumber = this.generateProfessionCount(min, max);
                const decl = declination(professionNumber, [
                    this.locale("profession-declination-1"),
                    this.locale("profession-declination-2"),
                    this.locale("profession-declination-3"),
                ]);
                text.title = this.locale("title-3");
                text.subtitle = this.locale("subtitle-3", {
                    count: professionNumber,
                    decl: decl
                });
            }
            if (this.completedCount >= 4) {
                const max =
                    professionsCount *
                    (this.categories.length * 0.35 * this.lastVoteIndex);
                const min = Math.trunc(max * 0.75);
                const professionNumber = this.generateProfessionCount(min, max);
                const decl = declination(professionNumber, [
                    this.locale("profession-declination-1"),
                    this.locale("profession-declination-2"),
                    this.locale("profession-declination-3"),
                ]);
                text.title = this.locale("title-4");
                text.subtitle = this.locale("subtitle-4", {
                    count: professionNumber,
                    decl: decl
                });
            }

            return text;
        },
    },
    watch: {
        showPopup(status) {
            if (status) {
                document
                    .querySelector(".welcome-layout")
                    .classList.add("hidden");
            } else {
                document
                    .querySelector(".welcome-layout")
                    .classList.remove("hidden");
            }
        },
        completedCount() {
            if (this.completedCount === 1) {
                this.stage = 1;
                this.showPopupHelps = true;
            }
            if (this.completedCount === 2) {
                this.stage = 4;
                this.showPopupHelps = false;
            }
            if (this.completedCount === 3) {
                this.stage = 5;
                this.showPopupHelps = true;
            }
            if (this.completedCount >= 4) {
                this.stage = 4;
                this.showPopupHelps = false;
                //lastStepIndex = 14;
            }
        },
    },
    async mounted() {
        this.yandexGoal("prof_test_start");
        await this.getProfessions();

        await this.$store.dispatch("set", {
            name: "onboardingCtrlStepNumber",
            value: 5,
        });

        if (this.completedCount === 1) {
            this.stage = 1;
            this.showPopupHelps = true;
        }
        if (this.completedCount === 2) {
            this.stage = 4;
            this.showPopupHelps = false;
        }
        if (this.completedCount === 3) {
            this.stage = 5;
            this.showPopupHelps = true;
        }
        if (this.completedCount >= 4) {
            this.stage = 4;
            this.showPopupHelps = false;
            //lastStepIndex = 14;
        }
    },
    methods: {
        declination,
        generateProfessionCount(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        openPopup(status) {
            if(this.completedCount === 1) {
                this.yandexGoal("open_first_profession_popup");
            }
            this.showPopup = status;
        },
        closePopup() {
            setTimeout(() => {
                this.showPopup = false;
            }, 100);
        },
        async getProfessions() {
            this.closePopup();
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 10,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.categories = [];
                    this.categories.push(...r.data.onBoardingGetStep.test);
                    this.completedCount = r.data.onBoardingGetStep.test.filter(
                        (test) => test.unlocked,
                    ).length;
                    if (this.completedCount === 14) {
                        this.lastVoteIndex = this.lastVoteIndex / 2;
                    }
                })
                .catch((e) => {
                    const codeError = e.graphQLErrors[0].extensions.code;

                    if (codeError === 1007) {
                        this.$router.push("/welcome/professions");
                    }
                });
        },
        getPaths(cats, step) {
            return cats.map((_, i) => {
                return this.pie(
                    Math.PI * 1.2 + step * i,
                    step,
                    this.outerRadius,
                    this.innerRadius,
                    this.gap,
                );
            });
        },
        pie(from, angle, outerRadius, innerRadius, gap = 0) {
            const or = outerRadius;
            const ir = innerRadius;
            let d;

            if (angle > Math.PI) {
                const x0 = or * Math.cos(from);
                const y0 = or * Math.sin(from);
                d = `M ${x0} ${y0} `;

                const x1 = or * Math.cos(from + Math.PI);
                const y1 = or * Math.sin(from + Math.PI);
                d += `A ${or} ${or} 0 0 1 ${x1} ${y1} `;
                d += `A ${or} ${or} 0 0 1 ${x0} ${y0} `;

                const x2 = ir * Math.cos(from);
                const y2 = ir * Math.sin(from);
                d += `M ${x2} ${y2} `;

                const x4 = ir * Math.cos(from + Math.PI);
                const y4 = ir * Math.sin(from + Math.PI);
                d += `A ${ir} ${ir} 0 0 0 ${x4} ${y4} `;

                d += `A ${ir} ${ir} 0 0 0 ${x2} ${y2} Z`;
            } else {
                const og = Math.asin(gap / 2 / outerRadius);
                const ig = Math.asin(gap / 2 / innerRadius);

                const x0 = or * Math.cos(from + og);
                const y0 = or * Math.sin(from + og);
                d = `M ${x0} ${y0} `;

                const x2 = or * Math.cos(from + angle - og);
                const y2 = or * Math.sin(from + angle - og);
                d += `A ${or} ${or} 0 0 1 ${x2} ${y2} `;

                const x3 = ir * Math.cos(from + angle - ig);
                const y3 = ir * Math.sin(from + angle - ig);
                d += `L ${x3} ${y3} `;

                const x4 = ir * Math.cos(from + ig);
                const y4 = ir * Math.sin(from + ig);
                d += `A ${ir} ${ir} 0 0 0 ${x4} ${y4} `;
                d += `L ${x0} ${y0} Z`;
            }

            const mid = from + angle / 2;

            return {
                d,
                cx: (or + ir) * 0.55 * Math.cos(mid) + angle * ir - 35 - 15,
                cy: (or + ir) * 0.55 * Math.sin(mid) + angle * ir - 20,
            };
        },
    },
};
</script>

<style lang="scss">
.welcome-profession-vote-header {
    padding: 0 25px;
    @media (max-width: 767px) {
        padding: 0 15px;
    }

    &__with-arrow {
        position: relative;

        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: -30px;
            right: -46px;
            background: url(../../assets/images/helper-arrow.png) center/cover
                no-repeat;
            width: 56px;
            height: 19px;
            transform: rotateZ(90deg);
        }
    }
}

.welcome-profession-vote {
    &__decor {
        overflow: initial;
    }

    &__illustration-box {
        position: relative;
        margin-top: 40px;
    }

    &__illustration {
        $w: 410px;
        top: calc(50% - #{$w} / 2 + 50px);
        left: calc(50% - #{$w} / 2);
        width: #{$w};
        height: #{$w};
        @media (max-width: 767px) {
        }
    }

    &__pie-group {
        transform: scale(1.9);
    }

    &__text-box {
        pointer-events: none;
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 7px !important;
        line-height: 1.2 !important;

        &.current {
            color: #222;
        }
    }

    &__pie-section {
        fill: rgba(#f8e5ad, 0.1);
        transition: 0.4s;

        &:hover {
            fill: rgba(#f8e5ad, 0.15);
        }

        &.current {
            fill: $gold;

            &:hover {
                cursor: pointer;
                fill: darken($gold, 5%);
            }
        }
    }

    &__unlocked {
        pointer-events: none;
        width: 16 * 0.75px;
        height: 23 * 0.75px;
        fill: rgba(#fff, 0.5);
    }
}
</style>
