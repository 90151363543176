<template lang="pug">
  .onboard-description-popup
    ._outer
      transition(name="fadeIn")
        svg-icon(icon-name="down-arrow" v-if="showScroll")._scroll-icon
      perfect-scrollbar._wrap(@ps-y-reach-end="showScroll = false" @ps-scroll-up="showScroll = true")
        ._item.onboard-description-popup__item-stage-1(
          :class="{ 'onboard-description-popup__item-blured': currentStage != 1 && helps || showProfessionHelp }"
        )
          h3._item-title {{ profession.name }}
          p._item-desc {{ profession.description }}
          onboard-description-popup-tip(
            v-if="helps"
            arrowPosition="center",
            @nextSlide="currentStage = 2",
            :text="locale('text')")
        ._item.onboard-description-popup__item-stage-2(
          :class="{ 'onboard-description-popup__item-blured': currentStage == 1  && helps }"
        )
          onboard-description-popup-radio(
            :title="profession.professions[0]",
            :show-description="stage === 2 && !professionHelp && step >= 14"
            subtitle="",
            @clickOnTitle="showProfessionHelp = false",
            :key="profession.professions[0]"
            @vote="changeProfessionVote"
          )



    ui-button._calculate-btn(
      @click.native="submitHandler",
      text="Рассчитать",
      color="gold"
    )
    ._error(v-if="isError && noVoteProfessions.length") {{ locale("error") }}
      br/ {{ noVoteProfessions.join(", ") }}
</template>

<script>
import OnboardDescriptionPopupTip from "./onboard-description-popup-tip/onboard-description-popup-tip";
import OnboardDescriptionPopupRadio from "./onboard-description-popup-radio/onboard-description-popup-radio";
import UiButton from "@/components/ui/ui-button/ui-button";
import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";

export default {
    name: "OnboardingDescriptionPopup",
    components: {
        OnboardDescriptionPopupTip,
        OnboardDescriptionPopupRadio,
        UiButton,
    },
    props: {
        stage: {
            // 1, 2, 3
            required: false,
            default: 1,
            type: Number,
        },
        step: {
            required: true,
            default: 0,
            type: Number,
        },
        profession: {
            required: true,
            default: () => ({
                id: 0,
                name: "",
                description: "",
                professions: [],
            }),
            type: Object,
        },
        professionHelp: {
            type: Boolean,
            default: false,
            required: false,
        },
        helps: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            currentStage: 1,
            showRememberedYourAnswerTip: true,
            showProfessionHelp: false,
            votes: {},
            isError: false,
            showScroll: true,
        };
    },
    computed: {
        result: function () {
            const result = [];
            this.profession.professions.forEach((p) => {
                const professionArray = [];
                professionArray.push(p);
                professionArray.push(this.votes[p]);
                result.push(professionArray);
            });
            return result;
        },
        noVoteProfessions: function () {
            const professionArray = [];
            this.profession.professions.forEach((p) => {
                if (!this.votes[p]) {
                    professionArray.push(p);
                }
            });
            return professionArray;
        },
        calculateAvailable: function () {
            return (
                this.profession.professions.length !==
                Object.keys(this.votes).length
            );
        },
    },
    created() {
        this.currentStage = this.stage; //from props
        this.showProfessionHelp = this.professionHelp;
    },
    methods: {
        submitHandler() {
            if (!this.calculateAvailable) {
                this.sendVotes();
                return;
            }
            this.isError = true;
        },
        changeProfessionVote(name, value) {
            this.$set(this.votes, name, value);
            this.currentStage = 4;
        },
        async sendVotes() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: this.step,
                        result: this.result,
                    },
                })
                .then(() => {
                    if (this.step === 14) {
                        this.$emit("getNewProfession");
                    } else {
                        this.redirectController();
                    }
                });
        },
    },
};
</script>

<style lang="scss">
@import "./onboard-description-popup.scss";
</style>
