<template lang="pug">
    welcome-info
        template(v-slot:title) {{ title }}
        template(v-slot:subtitle) Узнайте, какой из 59 психотипов принадлежит Вам!
        template(v-slot:content)
            .welcome-psychotype-pay
                ._list
                    ._type(v-for="(icon, name, index) in personTypeIcons" :class="{active: isActive}")
                        svg-icon( :key="index" :icon-name="icon" added-catalog="leader-types")._type-icon
                        ._type-name {{ name }}
                ui-button(text="Оплатить 499 руб." @click.native="pay()" :disabled="!link")._pay-btn
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import UiButton from "@/components/ui/ui-button/ui-button";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import onBoardingPassGetMutation from "@/graphql/development_mutations/onBoardingPassGet.mutation.gql";

export default {
    name: "WelcomeProfPay",
    components: {UiButton, WelcomeInfo},
    data() {
        return {
            link: "",
            isActive: false
        };
    },
    computed: {
        personTypeIcons: function () {
            const obj = this.$store.getters["personTypeIcons"];
            const numProperties = 18;
            const objKeys = Object.keys(obj);

            if (numProperties >= objKeys.length) {
                return obj;
            }

            const excludedKeys = ["_hidden_", "_locked_"];
            const selectedProperties = {};
            const objKeysCopy = objKeys.filter(key => !excludedKeys.includes(key));

            while (Object.keys(selectedProperties).length < numProperties) {
                const randomIndex = Math.floor(Math.random() * objKeysCopy.length);
                const randomKey = objKeysCopy.splice(randomIndex, 1)[0];
                selectedProperties[randomKey] = obj[randomKey];
            }

            return selectedProperties;
        },
        title: (state) =>
            state.userPassedOnBoardingTypesFromMixin.length <= 1
                ? "Узнай свой психотип за 5 минут"
                : "Узнай свой психотип за 1 минуту",
    },
    mounted() {
        setTimeout(() => {
            this.isActive = true;
        }, 300);
        this.getPayLink();
    },
    methods: {
        async getPayLink() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 7,
                    },
                })
                .then((r) => {
                    if(r.data.onBoardingGetStep.test === "Privileged user") {
                        return this.$router.push("/welcome/test");
                    }
                    this.link = r.data.onBoardingGetStep.test;
                });
        },
        async pay() {
            if (process.env.NODE_ENV === "development") {
                await this.$apollo
                    .mutate({
                        mutation: gql(onBoardingPassGetMutation),
                        variables: {
                            type: "PSYCHOTIC_DETECTION",
                        },
                    })
                    .then(() => {
                        this.$router.push(
                            "/welcome/pay-check?shp_ObPassType=1",
                        );
                    })
                    .catch((e) => {
                        if (e.graphQLErrors[0].extensions.code === 1007) {
                            this.$router.push(
                                "/welcome/pay-check?shp_ObPassType=1",
                            );
                        }
                    });
            } else {
                this.yandexGoal("psychotype_go_to_pay");
                window.location.href = this.link;
            }
        },
    },
};
</script>

<style lang="scss">
.welcome-psychotype-pay {
    $root: &;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width: 50vw;
        min-height: 40vh;
        margin-top: 40px;
        @media(max-width: 1199px) {
            width: 70vw;
        }
        @media(max-width: 991px) {
            width: 90vw;
        }
    }

    &__type {
        position: absolute;
        bottom: 0;
        opacity: 0;
        
        &.active {
            opacity: 1;
        }

        @for $i from 1 through 18 {
            &:nth-child(#{$i}) {
                transition: calc($i / 3)s;
                transition-delay: calc($i / 10)s;
            }
        }

        &:nth-child(1) {
            top: 0;
            left: 10%;

            #{$root} {
                &__type-icon {
                    width: 26px;
                    height: 26px;
                }
            }
        }

        &:nth-child(2) {
            top: 10%;
            left: 25%;

            #{$root} {
                &__type-icon {
                    width: 40px;
                    height: 40px;
                }
            }
            @media(max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(3) {
            top: 5%;
            left: 40%;

            #{$root} {
                &__type-icon {
                    width: 56px;
                    height: 56px;
                }
            }
        }

        &:nth-child(4) {
            top: 25%;
            left: 55%;

            #{$root} {
                &__type-icon {
                    width: 28px;
                    height: 28px;
                }
            }
            @media(max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(5) {
            top: 8%;
            left: 68%;

            #{$root} {
                &__type-icon {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        &:nth-child(6) {
            top: 12%;
            left: 82%;

            #{$root} {
                &__type-icon {
                    width: 56px;
                    height: 56px;
                }
            }
        }

        &:nth-child(7) {
            top: 25%;
            left: 3%;

            #{$root} {
                &__type-icon {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        &:nth-child(8) {
            top: 28%;
            left: 12%;

            #{$root} {
                &__type-icon {
                    width: 60px;
                    height: 60px;
                }
            }
            @media(max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(9) {
            top: 30%;
            left: 30%;

            #{$root} {
                &__type-icon {
                    width: 44px;
                    height: 44px;
                }
            }
        }

        &:nth-child(10) {
            top: 37%;
            left: 45%;

            #{$root} {
                &__type-icon {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &:nth-child(11) {
            top: 30%;
            left: 68%;

            #{$root} {
                &__type-icon {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        &:nth-child(12) {
            top: 40%;
            left: 85%;

            #{$root} {
                &__type-icon {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        &:nth-child(13) {
            top: 52%;
            left: 8%;

            #{$root} {
                &__type-icon {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        &:nth-child(14) {
            top: 60%;
            left: 25%;

            #{$root} {
                &__type-icon {
                    width: 36px;
                    height: 36px;
                }
            }

            @media(max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(15) {
            top: 70%;
            left: 40%;

            #{$root} {
                &__type-icon {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &:nth-child(16) {
            top: 60%;
            left: 59%;

            #{$root} {
                &__type-icon {
                    width: 60px;
                    height: 60px;
                }
            }
            @media(max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(17) {
            top: 70%;
            left: 75%;

            #{$root} {
                &__type-icon {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        &:nth-child(18) {
            top: 75%;
            left: 15%;

            #{$root} {
                &__type-icon {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        width: max-content;
        height: max-content;

        &:hover {
            #{$root} {
                &__type-name {
                    top: -34px;
                    opacity: 1;
                }
            }
        }
    }

    &__type-name {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -24px;
        left: 50%;
        transform: translateX(-50%);
        background: $gold;
        padding: 0 8px;
        height: 24px;
        width: max-content;
        color: #222;
        opacity: 0;
        transition: .4s;
    }

    &__pay-btn {
        margin-top: 40px;
    }
}
</style>
