<template lang="pug">
  .welcome-people-recommendations(:class="{peoples: step === 2}")
    transition-group(name="fade" mode="out-in" tag="div" appear)._wrap
      ._tip(v-if="step === 1" key="start")
        ._tip-fake-elements
          ._recommendation(@click="step = 2") Рекомендации людей
            span._recommendation-count +1
        ._tip-content
          p._tip-text У нас на сайте более 40 000 пользователей и есть люди, которые Вам очень подходят для обмена опытом или знакомства.
          p._tip-text Я учел ваши психотипы и особенности характера.
          p._tip-text Раз в 3 дня я буду показывать Вам интересного человека, который Вам очень подходит.
          p._tip-text Вы можете проверить с ним деловую совместимость и узнать чего бы Вы бы могли совместно добиться.
          p._tip-text.-help-arrow Нажмите сюда

      ._recommendations(v-if="step === 2" key="list")
        ._recommendations-wrap
          ._recommendations-list
            ._user
              ._user-avatar(@click="step = 3")
                user-photo(:photo="user.photo")
                ._user-press Нажмите сюда
              ._user-info
                ._user-name {{ user.name }}
                ._person-category
                  svg-icon(icon-name="sub-cat-prof")._person-category-icon
                  ._person-type Профессия и предназначение
          ._tip.-list
            ._tip-content
              p._tip-text Раз в 3 дня я буду рекомендовать Вам человека, которого подобрал специально для Вас.
              p._tip-text Я стараюсь максимально учесть Ваши особенности психики и интересы, чтобы подобрать самых полезных.
              p._tip-text Вы можете узнать его интересы и сильные стороны

      ._compare(v-if="step === 3" key="compare")
        ._tip.-compare
          ._tip-content
            p._tip-text Вы можете проверить с ним деловую совместимость и узнать, чего Вы можете совместно добиться.
            p._tip-text.-compare-arrow Нажмите сюда
          ._tip-fake-elements
            ._compare-list
              ._compare-item(v-for="compare in serviceTypes" :key="compare.id" :class="{active: compare.id === 'BUSINESS'}" @click="getCompare(compare)")
                ._compare-icon-box
                  svg-icon(:icon-name="compare.icon")._compare-icon
                ._compare-name {{ compare.name }}  
      ._tip.-message(v-if="step === 4" key="message")
        ._tip-fake-elements
          ui-button(text="Написать сообщение" type="bordered")._send-message
        ._tip-content
          p._tip-text Отлично, Вы всегда можете написать этому человеку или добавить его в друзья.
          p._tip-text Так же забыл сказать, что раз в 3 дня я буду рекомендовать Вас другим пользователям и продвигать. Если Вам будет кто то писать, не удивляйтесь, это я Вас рекомендовал.
          p._tip-text Раз в 3 дня я буду показывать Вам интересного человека, который Вам очень подходит.
          p._tip-text Не забудьте, если Вы что то умеете, то можете предложить свои услуги или то что у Вас хорошо получается.
          p._tip-text Если хотите можете написать ему и представиться, рассказать чем занимаетесь, но немного позже.
          p._tip-text
            ui-button(text="Продолжить" @click.native="nextStep()")


    transition(name="showCompareResult")
      compare-result-dialog(
        v-if="compareResultDialog.show && isUserAuth",
        :config="compareResultDialog.data"
        type="onBoarding"
        @close="step = 4"
      )
</template>
<script>
import UserPhoto from "@/components/ui/user-photo/user-photo";
import gql from "graphql-tag";
import myRecommendedPeopleQuery from "@/graphql/queries/myRecommendedPeople.query.graphql";
import { compareMixins } from "@/mixins/compare.mixins";
import CompareResultDialog from "@/components/compare-result-dialog/compare-result-dialog";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomePeopleRecommendations",
    components: { UiButton, CompareResultDialog, UserPhoto },
    mixins: [compareMixins],
    data() {
        return {
            step: 1,
            user: {
                person_id: process.env.VUE_APP_RECOMMENDED_PEOPLE_UUID,
                name: "Максим Михайловский",
                photo: "https://static.merlinface.com/photos/202110/7IHrDsBYBey.jpg",
                category: "PROFESSIONAL",
                personType: "",
            },
            activeType: "",
        };
    },
    computed: {
        serviceTypes: (state) =>
            state.$store.getters["serviceTypesMobile"].filter(
                (item) => item.type === "compare",
            ),
        personUuid: (state) => state.user.person_id,
        personAvatar: (state) => state.user.photo,
        personName: (state) => state.user.name,
        compareResultDialog: (state) =>
            state.$store.getters["compareResultDialog"],
    },
    mounted() {
        this.getPeoples();
    },
    methods: {
        async getPeoples() {
            await this.$apollo
                .query({
                    query: gql`
                        ${myRecommendedPeopleQuery}
                    `,
                    variables: {
                        category: "PROFESSIONAL",
                        page: 1,
                        itemsPerPage: 1,
                    },
                })
                .then((r) => {
                    if (r.data.myRecommendedPeople.list.length) {
                        this.user = r.data.myRecommendedPeople.list[0];
                    }
                });
        },
        async getCompare(compare) {
            if (compare.id !== "BUSINESS") return;

            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            this.activeType = compare;

            await this.getCompareResult(this.personUuid, compare.id)
                .then((r) => {
                    const result = r.data.compareWithPersonGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyCompare(compare.id);
                        return;
                    }

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: compare,
                            photo: this.personAvatar,
                            personUuid: this.isMyProfile ? "" : this.personUuid,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.$options.filters.localizeError("unknownError"),
                        this.$options.filters.localizeError("retry"),
                    );
                });
        },
        nextStep() {
            this.$router.push("/welcome/profession-vote");
        },
    },
};
</script>

<style lang="scss">
.welcome-people-recommendations {
    $root: &;
    position: relative;
    background: url(../../assets/images/blured-desktop-interface.jpg)
        center/cover;
    width: 100vw;
    height: 100vh;

    &__wrap {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../assets/images/blured-desktop-interface-peoples.jpg)
            center/cover;
        opacity: 0;
        transition: 0.4s;
    }

    &.peoples {
        &:after {
            opacity: 1;
        }
    }

    @media (max-width: 767px) {
        padding: 60px 15px;
        height: auto;
    }

    &__tip {
        display: flex;
        align-items: flex-end;
        padding: 15% 20px 0 20px;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
        }

        &_message {
            align-items: flex-start;
            padding: 10% 20px;

            @media (max-width: 767px) {
                padding: 20px;
            }
        }

        &_list {
            padding: 20px 0;
            margin-top: 60px;

            #{$root} {
                &__tip-content {
                    padding-left: 0;
                }
            }
        }

        &_compare {
            @media (max-width: 767px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #{$root} {
                &__tip-content {
                    padding-bottom: 90px;
                }

                &__tip-fake-elements {
                    @media (max-width: 767px) {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &__tip-fake-elements {
        display: flex;
        flex-direction: column;

        .ui-button {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
    }

    &__tip-content {
        padding-left: 100px;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            padding: 40px 0;
        }
    }

    &__tip-text {
        color: #fff;
        font-size: 18px;
        line-height: 1.5;
        width: 100%;
        max-width: 500px;
        @media (max-width: 990px) {
            font-size: 16px;
            max-width: 400px;
        }
        @media (max-width: 767px) {
            font-size: 14px;
        }

        &:not(:first-child) {
            margin-top: 27px;
        }

        &_help-arrow {
            position: relative;

            @media (max-width: 767px) {
                order: -1;
                margin-bottom: 27px;
            }

            &:after {
                content: "";
                position: absolute;
                left: -80px;
                display: inline-block;
                width: 62px;
                height: 18px;
                background: url(../../assets/svg/onboard-tip-arrow-left.svg)
                    center/cover;
                @media (max-width: 767px) {
                    left: 0;
                    top: -40px;
                    transform: rotateZ(60deg);
                }
            }
        }

        &_compare-arrow {
            position: relative;
            text-align: right;
            padding-right: 40px;

            @media (max-width: 767px) {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                right: 30px;
                bottom: -50px;
                display: inline-block;
                width: 62px;
                height: 18px;
                background: url(../../assets/svg/onboard-tip-arrow-left.svg)
                    center/cover;
                transform: rotateZ(45deg) rotateY(180deg);
            }
        }
    }

    &__recommendation {
        background: #292929;
        color: #8a8a8a;
        font-size: 13px;
        padding: 10px 15px;
        transition: 0.4s;
        flex: none;

        &:hover {
            cursor: pointer;
            background: darken(#292929, 1%);
        }
    }

    &__recommendation-count {
        display: inline-block;
        margin-left: 10px;
        background: #31302c;
        color: $gold;
        font-size: 12px;
        padding: 3px 5px;
    }

    &__recommendations {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 5% 0 0 0;
    }

    &__recommendations-wrap {
        width: 100%;
        max-width: 600px;
    }

    &__recommendations-list {
    }

    &__user {
        display: flex;
    }

    &__user-avatar {
        position: relative;
        width: 80px;
        height: 80px;
        border: 1px solid transparent;
        transition: 0.4s;

        &:hover {
            cursor: pointer;
            border-color: $gold;
        }
    }

    &__user-press {
        position: absolute;
        top: 100%;
        left: -150px;
        width: 130px;
        color: #fff;
        font-size: 18px;
        text-align: center;

        @media (max-width: 990px) {
            font-size: 12px;
            width: 100px;
            top: calc(100% + 10px);
            left: -90px;
        }
        @media (max-width: 767px) {
            left: 0;
            top: initial;
            bottom: -90px;
            text-align: left;
        }

        &:after {
            content: "";
            position: absolute;
            top: -65px;
            right: 15px;
            display: inline-block;
            width: 24px;
            height: 62px;
            background: url(../../assets/svg/onboard-tip-arrow.svg) center/cover;
            transform: rotateY(180deg) rotateZ(-45deg);
            @media (max-width: 990px) {
                right: 35px;
            }
            @media (max-width: 767px) {
                transform: rotateZ(0);
                width: 20px;
                height: 58px;
            }
        }
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
    }

    &__user-name {
        font-size: 19px;
        color: #fff;
    }

    &__person-category {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    &__person-category-icon {
        width: 20px;
        height: 20px;
        color: $gold;
        margin-right: 10px;
        flex: none;
    }

    &__person-type {
        color: #959595;
        font-size: 12px;
        margin-top: 5px;
    }

    &__compare {
        display: flex;
        justify-content: flex-end;
    }

    &__compare-list {
        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__compare-item {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        background: #505050;
        width: 100%;
        max-width: 225px;
        pointer-events: none;
        @media (max-width: 767px) {
            max-width: 480px;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }

        &.active {
            pointer-events: initial;
            background: $gold;
            cursor: pointer;
            transition: 0.4s;

            &:hover {
                background: darken($gold, 5%);
            }
        }
    }

    &__compare-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
    }

    &__compare-icon {
        fill: #222 !important;
        width: 20px;
        height: 20px;
    }

    &__compare-name {
        color: #222;
        padding-left: 15px;
        text-transform: uppercase;
    }

    &__send-message {
        pointer-events: none;
    }
}
</style>
