<template lang="pug">
  welcome-info
    template(v-slot:title) Я проанализировал всю информацию о Вас
    template(v-slot:subtitle) Для Вас есть персональные рекомендации, которые подобраны индивидуально
      br/ под ваш характер и помогут развить новые навыки
      br/br/ Нажмите на первую рекомендацию, чтоб узнать, как этого добиться.
    template(v-slot:content)
      .welcome-recommendations
        loading(v-if="isLoading")
        ._content
          service-collapse(
            v-for="(item, index) in recommendations"
            :title="`Рекомендация №${index + 1}`"
            :key="item.id"
            :open="false"
            :design="!item.unlockAvailable ? 'gray' : ''"
            :class="{disabled: !item.unlockAvailable && !item.text}"
            :serviceIndex="item.text"
            @click.native="getNewRecommendation(item)"
          )._item
            template(slot="ctrl")
              ._item-timer(v-if="item.unlockAvailableAt && !item.unlockAvailable" data-text) {{ timeLeft }}
              ._item-timer.-availabel(v-if="item.unlockAvailableAt && item.unlockAvailable") Можно открыть
              ._item-timer.-locked(v-if="!item.unlockAvailable && !item.text && !item.unlockAvailableAt")
                svg-icon(icon-name="lock")._item-lock-icon
            ._item-content(slot="content" v-html="formatText(item.text)")
        ui-button(text="Продолжить" :disabled="!isRecommendationOpen" @click.native="nextStep()")._btn
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";

import gql from "graphql-tag";
import myPersonRecommendationsQuery from "@/graphql/queries/myPersonRecommendations.query.graphql";
import unlockRecommendationMutation from "@/graphql/mutations/unlockRecommendation.mutation.graphql";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomeStart",
    components: { UiButton, WelcomeInfo },
    data() {
        return {
            recommendations: [],
            timeLeft: null,
            isLoading: true,
            isUserOpen: false,
        };
    },
    computed: {
        isRecommendationOpen: (state) =>
            state.recommendations.filter(
                (recommendation) => recommendation.unlocked,
            ).length,
        openingTime: (state) =>
            state.recommendations.find((item) => item.unlockAvailableAt)
                ?.unlockAvailableAt,
    },
    watch: {
        isRecommendationOpen(count) {
            if (count) {
                if (!this.isUserOpen) {
                    this.nextStep();
                }
            } else {
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        await this.getRecommendations();
        this.timeLeft = this.getRemainingTime(this.openingTime);
        setInterval(() => {
            this.timeLeft = this.getRemainingTime(this.openingTime);
        }, 1000);
    },
    methods: {
        async getRecommendations() {
            await this.$apollo
                .query({
                    query: gql`
                        ${myPersonRecommendationsQuery}
                    `,
                })
                .then((r) => {
                    this.isLoading = false;
                    this.recommendations = r.data.myPersonRecommendations
                        .find((category) => category.category.id === 1)
                        .recommendations[0].items.filter(
                            (item, index) => index <= 2,
                        );
                });
        },
        async getNewRecommendation(item) {
            this.isUserOpen = true;
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${unlockRecommendationMutation}
                    `,
                    variables: {
                        id: parseInt(item.id),
                    },
                })
                .then((r) => {
                    const result = r.data.unlockRecommendation;
                    this.getRecommendations();
                    if (result.unlocked) {
                        this.isOpen = true;
                    }
                })
                .catch((e) => {
                    if (e.graphQLErrors[0].extensions.code === 1007) {
                        this.getRecommendations();
                        this.isOpen = true;
                    }
                });
        },
        formatText(text) {
            if (text) {
                return text.replace(/\n/g, "<br/>");
            }
            return "";
        },
        nextStep() {
            this.$router.push("/welcome/profession-vote");
        },
    },
};
</script>

<style lang="scss">
.welcome-recommendations {
    max-width: 100%;
    width: 500px;

    &__item {
        &.disabled {
            pointer-events: none;
            opacity: 0.7;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &__item-content {
        padding: 10px 15px 25px 15px;
        color: #fff;
        line-height: 1.5;
        text-align: left;
    }

    &__item-timer {
        font-size: 12px;
        color: $gold;

        &:before {
            content: attr(data-text);
            @media (max-width: 420px) {
                display: none;
            }
        }

        &_availabel {
            &:before {
                content: "";
            }
        }

        &_locked {
            &:before {
                content: "";
            }
        }
    }

    &__item-lock-icon {
        width: 16px;
        height: 16px;
        fill: #868686;
    }

    &__btn {
        margin-top: 30px;
    }
}
</style>
