<template lang="pug">
  welcome-info(link="/welcome/people-recommendations" link-text="Посмотреть")
    template(v-slot:title) Супер.
    template(v-slot:content) Из Ваших ответов я узнал еще очень много полезного.
      br/ Сейчас покажу, Вам точно будет интересно.
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
export default {
    name: "WelcomeTalentsReady",
    components: { WelcomeInfo },
    data() {
        return {};
    },
};
</script>
