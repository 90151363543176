<template lang="pug">
  welcome-info(v-if="!loading")
    template(v-slot:title) 
        span.welcome-test-complete__title(:class="{active: isShowType}") {{ locale("title") }}
    template(v-slot:content)
      .welcome-test-complete(v-if="personType")
        ._person-type
          ._person-type-icon-box(:class="{active: isShowType}")
            svg-icon(:icon-name="personTypeIcon" addedCatalog="leader-types")._person-type-icon
          ._person-type-name(:class="{active: isShowType}") {{ personType }}
          ._person-type-descsription(:class="{active: isShowType}") {{ personTypeDescription }}
        ui-button(:text="locale('next-btn')" @click.native="completeOnBoarding" :class="{active: isShowType}")._next-btn
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import declination from "@/utils/declination";
import UiButton from "@/components/ui/ui-button/ui-button";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";

export default {
    name: "WelcomeTestComplete",
    components: { UiButton, WelcomeInfo },
    data() {
        return {
            withHighCompatibility: null,
            sameType: null,
            celebritiesSameType: null,
            personType: "",
            isShowType: false
        };
    },
    computed: {
        personTypeIcons: (state) => state.$store.getters["personTypeIcons"],
        personTypeIcon: (state) => state.personTypeIcons[state.personType],
        personTypeDescription: state => state.locale("typeDescriptions")[state.personType]
    },
    mounted() {
        setTimeout(() => {
            this.isShowType = true;
        }, 1000);
        this.getStats();
    },
    methods: {
        declination,
        async getStats() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 10,
                    },
                })
                .then((r) => {
                    const stats = r.data.onBoardingGetStep.stats;
                    this.sameType = stats.sameType;
                    this.withHighCompatibility = stats.withHighCompatibility;
                    this.celebritiesSameType = stats.celebritiesSameType;
                    this.personType = stats.personType;
                    this.loading = false;
                    this.yandexGoal("psychotype_show_type");
                });
        },
        async completeOnBoarding() {
            await this.completeStep(10);
            await this.completeStep(11);
            this.yandexGoal("psychotype_complete");
        },
        async completeStep(step) {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: step,
                        result: step === 10 ? [["done", 1]] : 1,
                    },
                })
                .then(async () => {
                    if (step === 11) {
                        await this.getOnBoardingTypesFromMixin();
                        await this.$router.push("/analyze");
                    }
                });
        },
    },
};
</script>

<style lang="scss">
.welcome-test-complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    &__title {
        font-weight: bold;
        font-size: 30px;
        transform: translateY(100px);
        opacity: 0;
        transition: 1s;
        transition-delay: .1s;

        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__person-type {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 60px;
        @media(max-width: 767px) {
            margin-top: 30px;
        }
    }

    &__person-type-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 175px;
        height: 175px;
        border: 1px solid #2b2b2c;
        transform: scale(3);
        opacity: 0;
        transition: 1s;
        @media(max-width: 767px) {
            width: 140px;
            height: 140px;
        }

        &.active {
            transform: scale(1);
            opacity: 1;
        }
    }

    &__person-type-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        fill: #222;
        @media(max-width: 767px) {
            width: 60px;
            height: 60px;
        }
    }

    &__person-type-name {
        margin-top: 30px;
        font-size: 24px;
        transform: translateY(100px);
        opacity: 0;
        transition: 1s;
        transition-delay: .4s;

        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__person-type-descsription {
        margin-top: 30px;
        font-size: 18px;
        transform: translateY(100px);
        opacity: 0;
        transition: 1s;
        transition-delay: .8s;
        @media(max-width: 767px) {
            font-size: 16px;
        }

        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__next-btn {
        margin-top: 40px;
        transform: translateY(100px);
        opacity: 0;
        transition: 1s;
        transition-delay: 1.5s;

        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
</style>
