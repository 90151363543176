<template lang="pug">
    .welcome-want
        loading
        //welcome-info._header
        //    template(v-slot:title) {{ locale("title") }}
        //    template(v-slot:subtitle)
        //        span(v-html="locale('description')")
        //    template(v-slot:content)
        //        ._illustration-box
        //            vue-lottie-player(autoplay controls loop mode="normal" :animationData="analyzeFile")._player
        //            svg._illustration(x="0px" y="0px" viewBox="0 0 534 533" style="enable-background:new 0 0 534 533;" xml:space="preserve")
        //                g
        //                    path._section(v-for="(step, index) in baseSteps" :d="step.path" @click="chooseStep(step.id)" :class="{disabled: index > activePathsCount}")
        //                g(v-for="(step, index) in steps" :transform="step.transform")
        //                    svg(:x="step.lockTransform.x" :y="step.lockTransform.y" width="16" height="23" viewBox="0 0 16 23" v-if="index > activePathsCount")._locked
        //                        path(d="M14.347,22.629 L1.652,22.629 C0.919,22.629 0.324,22.10 0.324,21.248 L0.324,10.533 C0.324,9.770 0.919,9.152 1.652,9.152 L2.449,9.152 L2.449,6.170 C2.449,2.967 4.946,0.370 8.26,0.370 C11.106,0.370 13.603,2.967 13.603,6.170 L13.603,9.152 L14.347,9.152 C15.80,9.152 15.675,9.770 15.675,10.533 L15.675,21.248 C15.675,22.10 15.80,22.629 14.347,22.629 ZM12.541,6.252 C12.541,3.675 10.531,1.585 8.53,1.585 C5.574,1.585 3.564,3.675 3.564,6.252 L3.564,9.152 L12.541,9.152 L12.541,6.252 ZM14.612,10.919 C14.612,10.553 14.327,10.257 13.975,10.257 L2.24,10.257 C1.672,10.257 1.386,10.553 1.386,10.919 L1.386,20.861 C1.386,21.227 1.672,21.524 2.24,21.524 L13.975,21.524 C14.327,21.524 14.612,21.227 14.612,20.861 L14.612,10.919 ZM9.141,16.968 L9.141,18.403 C9.141,19.59 8.630,19.591 7.999,19.591 C7.369,19.591 6.857,19.59 6.857,18.403 L6.857,16.937 C6.189,16.522 5.742,15.764 5.742,14.896 C5.742,13.585 6.764,12.521 8.26,12.521 C9.287,12.521 10.310,13.585 10.310,14.896 C10.310,15.786 9.839,16.562 9.141,16.968 Z")
        //                    //svg(:x="step.lockTransform.x" :y="step.lockTransform.y" viewBox="0 0 28 33" width="28" height="33" v-if="index === 2")
        //                    //  linearGradient(id="merlin-dating-gradient" gradientUnits="userSpaceOnUse" x1="27.2707" y1="0.6396" x2="0.6578" y2="32.5751")
        //                    //    stop(offset="0" style="stop-color:#D086B8")
        //                    //    stop(offset="0.9379" style="stop-color:#5FB1E3")
        //                    //    stop(offset="1" style="stop-color:#4FB2E5")
        //                    //  rect(y="0.1" class="st0" width="28" height="32.9")._merlin-dating-bg
        //                    //  path(class="st1" d="M18,12.2h-2.4l-1.6,5.7l-1.6-5.7H9.9v12.6h2v-8.5l1.2,4.5h1.5l1.3-4.5v8.5h2V12.2z")._merlin-dating-letter
        //                    //  path(class="st1" d="M18,7h-8v2h8V7z")._merlin-dating-letter
        //                    text._text(:class="{disabled: index > activePathsCount}")
        //                        tspan(x="0" y="0") {{ step.line[0] }}
        //                        tspan(:x="step.lineTransform.x" :y="step.lineTransform.y" v-if="step.line[1]") {{ step.line[1] }}
        //                        tspan(:x="step.dateTransform.x" :y="step.dateTransform.y" v-if="step.line[2]")._soon-text {{ step.line[2] }}
        //transition(name="fade")
        //    merlin-dating-pay(v-if="showMerlinDatingPay" @close="showMerlinDatingPay = false")
</template>

<script>
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import VueLottiePlayer from "vue-lottie-player";
import MerlinDatingPay from "@/components/merlin-dating-pay/merlin-dating-pay";
import getMyUserQuery from "@/graphql/queries/getMyUser.query.graphql";

export default {
    name: "WelcomeWant",
    components: { MerlinDatingPay, WelcomeInfo, VueLottiePlayer },
    data() {
        return {
            //showMerlinDatingPay: false,
            activePathsCount: 2,
            isLoading: true,
            analyzeFile: require("../lottie-files/analyze"),
            baseSteps: [
                {
                    id: 1,
                    text: "",
                    lines: [[1], [2]],
                    line: {
                        0: "",
                    },
                    transform: "matrix(1 0 0 1 135.3633 113.4911)",
                    lineTransform: {
                        x: -17,
                        y: 16.8,
                    },
                    dateTransform: {
                        x: 0,
                        y: 0,
                    },
                    lockTransform: {
                        x: -6.8,
                        y: 40,
                    },
                    path: "M172.8,233.6L14.5,182.3C49.6,77,148.6,0.9,265.5,0v166.2C222.6,167.3,186.4,195.1,172.8,233.6z",
                },
                {
                    id: 2,
                    text: "",
                    lines: [[1], [2]],
                    line: {
                        0: "",
                        1: "",
                    },
                    transform: "matrix(1 0 0 1 339.8322 113.4909)",
                    lineTransform: {
                        x: 10,
                        y: 16.8,
                    },
                    dateTransform: {
                        x: 0,
                        y: 0,
                    },
                    lockTransform: {
                        x: -6.8,
                        y: 40,
                    },
                    path: "M270.4,166.2V0c116.6,1.3,215.2,77.4,250,182.6l-157.3,50.9C349.6,195,313.3,167.2,270.4,166.2z",
                },
                {
                    id: "",
                    text: "",
                    lines: [[1], [2, 3]],
                    line: {
                        0: "",
                        1: "",
                    },
                    transform: "matrix(1 0 0 1 405.3396 330.2017)",
                    lineTransform: {
                        x: -3,
                        y: 16.8,
                    },
                    dateTransform: {
                        x: -10,
                        y: 40,
                    },
                    lockTransform: {
                        x: 13,
                        y: -50,
                    },
                    path: "M427.7,479l-97.7-133c23.6-18.5,38.7-47.2,38.7-79.4c0-10-1.5-19.6-4.2-28.7L522,186.9c7.8,25,12,51.6,12,79.2C533.9,353.1,492.2,430.3,427.7,479z",
                },
                {
                    id: 4,
                    text: "",
                    lines: [[1], [2, 3]],
                    line: {
                        0: "",
                        1: "",
                        2: "",
                    },
                    transform: "matrix(1 0 0 1 226.8426 441.1829)",
                    lineTransform: {
                        x: -20.7,
                        y: 16.8,
                    },
                    dateTransform: {
                        x: 5,
                        y: 40,
                    },
                    lockTransform: {
                        x: 34,
                        y: -50,
                    },
                    path: "M268,367.8c21.6,0,41.7-6.8,58.1-18.4l97.6,133c-43.9,31.8-97.9,50.6-156.3,50.6c-58,0-111.7-18.6-155.5-50l98-133.5C226.4,361,246.4,367.8,268,367.8z",
                },
                {
                    id: 5,
                    text: "",
                    lines: [[1], [2, 3]],
                    line: {
                        0: "",
                        1: "",
                        2: "",
                    },
                    transform: "matrix(1 0 0 1 62.1453 313.4955)",
                    lineTransform: {
                        x: -14.5,
                        y: 16.8,
                    },
                    dateTransform: {
                        x: -10,
                        y: 40,
                    },
                    lockTransform: {
                        x: 19,
                        y: -50,
                    },
                    path: "M167.2,267c0,32.3,15.2,61,38.8,79.5L107.9,480c-65-48.6-107-126.2-107-213.5c0-27.7,4.2-54.4,12-79.4l158.3,51.3C168.6,247.4,167.2,257,167.2,267z",
                },
            ],
        };
    },
    computed: {
        steps: function () {
            return this.baseSteps.map((step) => {
                const result = {
                    id: step.id,
                    transform: step.transform,
                    line: step.line,
                    lineTransform: step.lineTransform,
                    dateTransform: step.dateTransform,
                    lockTransform: step.lockTransform,
                };

                if (step.lines) {
                    step.lines.forEach((line, index) => {
                        result.line[index] = line
                            .map((text) => {
                                return step.text.split(" ")[text - 1];
                            })
                            .join(" ");
                    });
                }
                return result;
            });
        },
    },
    async mounted() {
        this.chooseStep(62);
    },
    methods: {
        getStep() {
            this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 1,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const values = r.data.onBoardingGetStep.poll.values;

                    values.forEach((value, index) => {
                        //if (index === 2) return;
                        this.baseSteps[index].id = value.id;
                        this.baseSteps[index].text = value.value;
                    });
                });
        },
        chooseStep(id) {
            //if (id === "MerlinDating") {
            //    this.showMerlinDatingPay = true;
            //    return;
            //}
            this.isLoading = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 1,
                        result: id,
                    },
                })
                .then(() => {
                    this.checkPersonCreated();
                });
        },
        async checkPersonCreated() {
            await this.$apollo
                .query({
                    query: gql(getMyUserQuery),
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    if (r.data.getMyUser.person.extras.external_id_present && r.data.getMyUser.on_boarding_completed) {
                        this.$store.dispatch(
                            "person/setPerson",
                            r.data.getMyUser.person,
                        );
                        this.redirectController();
                    } else {
                        setTimeout(async () => {
                            await this.checkPersonCreated();
                        }, 1000);
                    }
                });
        },
    },
};
</script>

<style src="./want.scss" lang="scss"></style>
