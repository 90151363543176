<template lang="pug">
.onboard-description-popup-tip
	svg-icon._arrow(
		v-if="!bottomArrow && showArrow",
		iconName="onboard-tip-arrow",
		:class="`onboard-description-popup-tip__arrow-${arrowPosition}`"
	)
	p._desc(v-html="text") 
	ui-button._next._next(
		v-if="showBtn",
		@click.native="$emit('nextSlide')",
		text="Дальше",
		color="gold",
		size="big"
	)
	svg-icon._arrow-bottom(
		v-if="bottomArrow && showArrow",
		iconName="onboard-tip-arrow",
		:class="`onboard-description-popup-tip__arrow-${arrowPosition}`"
	)
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    components: { UiButton },
    props: {
        text: {
            type: String,
            required: true,
        },
        arrowPosition: {
            // left, center, right
            type: String,
            required: false,
            default: "center",
        },
        showArrow: {
            required: false,
            default: true,
            type: Boolean,
        },
        showBtn: {
            required: false,
            default: true,
            type: Boolean,
        },
        bottomArrow: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./onboard-description-popup-tip.scss";
</style>
