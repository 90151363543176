<template lang="pug">
  welcome-info
    template(v-slot:title) {{ locale("loading") }}
    template(v-slot:content)
      loading
</template>
<script>
import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";

export default {
    name: "WelcomeBusinessCardPay",
    components: { WelcomeInfo },
    data() {
        return {
            isReady: false,
            isSuccess: false,
        };
    },
    mounted() {
        this.checkPay();
    },
    methods: {
        async checkPay() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 7,
                        result: 1,
                    },
                })
                .then(() => {
                    this.redirectController();
                });
        },
    },
};
</script>
