<template lang="pug">
    .onboard-description-popup-radio(:class="{error: error, pulse: stage === 1}")
        ._title(@click="getProfessionDescription()" :class="{active: showSub}") {{ title }}
            svg-icon(icon-name="down-arrow")._title-arrow
        transition-expand
            ._subtitle(v-if="showSub && description") {{ description }}
                //a._referal-btn(v-if="link" :href="link" target="_blank") {{ textLink }}
        ._radio-wrap
            ._radio-wrap-rate(@mouseleave="text = ''")
                ._radio-wrap-rate-item(
                    v-for="(item, idx) in items",
                    @mouseover="text = item.text",
                    @click="clickHandler(item)",
                    :key="idx",
                    :class="[`onboard-description-popup-radio__radio-wrap-rate-item-${idx + 1}`, { 'onboard-description-popup-radio__radio-wrap-rate-item-active': item.active }]"
                )
            span._radio-wrap-tip {{ text }}
</template>

<script>
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import gql from "graphql-tag";
import getAdvertQuery from "@/graphql/queries/getAdvert.query.graphql";

export default {
    name: "OnboardDescriptionPopupRadio",
    components: { TransitionExpand },
    props: {
        title: {
            type: String,
            required: true,
        },
        showDescription: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: true,
            default: false,
        },
        stage: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            text: "",
            showSub: false,
            description: "",
            linkId: null,
            link: null,
            textLink: null,
            items: [
                {
                    id: 1,
                    text: "",
                    showSub: false,
                    active: false,
                },
                {
                    id: 2,
                    text: "",
                    active: false,
                },
                {
                    id: 3,
                    text: "",
                    active: false,
                },
                {
                    id: 4,
                    text: "",
                    active: false,
                },
                {
                    id: 5,
                    text: "",
                    active: false,
                },
                {
                    id: 6,
                    text: "",
                    active: false,
                },
            ],
        };
    },
    mounted() {
        const localeList = this.locale("list");
        this.items = this.items.map((item, index) => {
            console.log(localeList[index].text);
            return { ...item, text: localeList[index].text };
        });
        if (this.showDescription) {
            this.getProfessionDescription();
        }
    },
    methods: {
        clickHandler(e) {
            this.items.forEach((el) => (el.active = false));
            this.items.forEach((el) => {
                if (el.id === e.id) {
                    this.$set(el, "active", true);
                }
            });
            this.showSub = false;
            this.$emit("vote", this.title, e.id);
        },
        async getProfessionDescription() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getAdvertQuery}
                    `,
                    variables: {
                        term: this.title,
                    },
                    fetchPolicy: "no-cache"
                })
                .then((r) => {
                    this.description = r.data.getAdvert.description;
                    this.linkId = r.data.getAdvert.id;
                    this.link = r.data.getAdvert.url;
                    this.textLink = r.data.getAdvert.text;
                    this.showSub = !this.showSub;
                    this.$emit("clickOnTitle");
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./onboard-description-popup-radio.scss";
</style>
