<template lang="pug">
  welcome-info(:list="list" type="subscribe")
    template(v-slot:title) {{ locale("title") }}
    template(v-slot:subtitle)
        span(v-html="locale('subtitle')")
    template(v-slot:content)
      .welcome-subscribe
        ui-button(:text="subscribeText" @click.native="subscribe" v-if="!myUser.isUsePromo")._btn
        ui-button(:text="locale('to-profile')" type="bordered" color="gray" @click.native="endSteps()")._btn
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import UiButton from "@/components/ui/ui-button/ui-button";
import gql from "graphql-tag";
import subscriptionsPlansQuery from "@/graphql/queries/subscriptionPlans.query.graphql";
import { mutationSubscribeToPlan } from "@/components/subscriptions/graphql/mutations";

export default {
    name: "WelcomeSubscribe",
    components: { UiButton, WelcomeInfo },
    data() {
        return {
            price: 0,
            planId: 0,
        };
    },
    computed: {
        subscribeText: function () {
            return this.locale("pay", {price: this.price});
        },
        list: state => state.locale("list")
    },
    mounted() {
        this.getTariff();
    },
    methods: {
        async getTariff() {
            await this.$apollo
                .query({
                    query: gql(subscriptionsPlansQuery),
                    variables: {
                        periodicity: "MONTHLY",
                    },
                })
                .then((r) => {
                    this.price = r.data.subscriptionPlans[0].plans[1].price;
                    this.planId = r.data.subscriptionPlans[0].plans[1].id;
                });
        },
        async subscribe() {
            this.yandexGoal("subscribe");
            await this.$apollo
                .mutate({
                    mutation: mutationSubscribeToPlan,
                    variables: {
                        plan: Number(this.planId),
                    },
                })
                .then((r) => {
                    window.location.href = r.data.subscribeToPlan;
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1002: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Выбранный план не найден",
                                );
                                break;
                            }
                            case 1005: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Не выбран ни один план",
                                );
                                break;
                            }
                            default: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Неизвестная ошибка",
                                );
                            }
                        }
                    }
                });
        },
        async endSteps() {
            this.yandexGoal("to_profile");
            await this.getOnBoardingTypesFromMixin();
            await this.$router.push("/analyze");
        },
    },
};
</script>

<style lang="scss">
.welcome-subscribe {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin-top: 40px;

    &__btn {
        width: 100%;
        margin-top: 15px;
    }
}
</style>
