<template lang="pug">
    welcome-info
        template(v-slot:title)
        template(v-slot:content)
            .welcome-prof-pay
                template
                    ._illustration-box
                        transition(name="fadeIn")
                            img(src="../../assets/images/prof-pay/prof-pay-illustration-1.svg" v-if="activeIllustration === 1")._illustration.-first
                        transition(name="fadeIn")
                            img(src="../../assets/images/prof-pay/prof-pay-illustration-2.svg" v-if="activeIllustration === 2")._illustration.-second
                        transition(name="fadeIn")
                            img(src="../../assets/images/prof-pay/prof-pay-illustration-3.png" v-if="activeIllustration === 3")._illustration.-third
                    ui-button(:text="locale('open-btn')" size="big" @click.native="pay" color="orange" effect="flash" :disabled="!link")._pay-btn
                    ._help-text {{ locale("motivation") }}
    
                    ._pay-box
                        ._pay-box-inner
                            ._title
                                span._number {{ locale("desc-title", {count: memberCount}) }}
                                ._text(v-html="locale('desc')")
                            
                        ._reset-test(@click="confirmResetTest") {{ locale("recreate-text.btn") }}
                        ._partners
                            ._item
                                img(src="../../assets/images/partners/rf.png")._item-img
                                ._item-text {{ locale("partners.list")[0] }}
                            ._item
                                img(src="../../assets/images/partners/fsi.png")._item-img
                                ._item-text {{ locale("partners.list")[1] }}
                            ._item
                                img(src="../../assets/images/partners/b20.png")._item-img
                                ._item-text {{ locale("partners.list")[2] }}
                            ._item
                                img(src="../../assets/images/partners/sk.png")._item-img
                                ._item-text {{ locale("partners.list")[3] }}
                            ._item
                                img(src="../../assets/images/partners/tg.png")._item-img
                                ._item-text {{ locale("partners.list")[4] }}
                transition(name="fadeIn")
                    ._free-modal(v-if="freeModalPopup")
                        ._free-modal-dialog
                            ._free-modal-content
                                ._free-modal-inner
                                    svg-icon(icon-name="cross" @click.native="freeModalPopup = false")._cross-icon
                                    ._free-modal-icon-box ?
                                        svg-icon(icon-name="free-question")._free-modal-icon
                                    ._free-modal-title Сомневаетесь? Для вас есть эксклюзивное предложение.
                                    ._free-modal-text Мы готовы отдать вам результаты теста БЕСПЛАТНО!
                                        br/ Нажмите кнопку «написать»
                                        br/br/ Отправьте в чате сообщение «ХОЧУ РЕЗУЛЬТАТ».
                                        br/ И ответьте на 2 вопроса.
                                    ._free-modal-footer
                                        ui-button(color="orange" right-icon="telegram" size="big" effect="flash" text="Написать" @click.native="redirectToTelegram()")
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import UiButton from "@/components/ui/ui-button/ui-button";
import gql from "graphql-tag";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import onBoardingPassGetMutation from "@/graphql/development_mutations/onBoardingPassGet.mutation.gql";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import onBoardingResetMutation from "@/graphql/mutations/onBoardingReset.mutation.graphql";

export default {
    name: "WelcomeProfPay",
    components: {UiButton, WelcomeInfo},
    data() {
        return {
            activeIllustration: 1,
            illustrationInterval: null,
            link: "",
            step: 1,
            payed: false,
            memberCount: 384983,
            justCopied: false,
            isShowQrCode: false,
            timer: null,
            time: 60,
            interval: null,
            freeModalPopup: false
        };
    },
    computed: {
        formattedTime: function () {
            let minutes, seconds;
            minutes = Math.floor((this.timer / 1000 / 60) << 0);
            seconds = Math.floor((this.timer / 1000) % 60);

            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            if (seconds === 0) {
                seconds = "00";
            }

            return minutes + ":" + seconds;
        },
        title: (state) =>
            state.step === 1
                ? state.userPassedOnBoardingTypesFromMixin.length <= 1
                    ? state.locale("title-1")
                    : ""
                : state.locale("title-2"),
    },
    created() {
        if (localStorage.getItem("saleTimer")) {
            this.time = localStorage.getItem("saleTimer");
        }
        this.timer = this.time * 60 * 1000;
    },
    mounted() {
        this.yandexGoal("went_to_payment_page");
        this.illustrationInterval = setInterval(() => {
            if (this.activeIllustration === 3) {
                this.activeIllustration = 1;
            } else {
                this.activeIllustration++;
            }
        }, 2000);
        
        if(this.myUser.isUsePromo) {
            this.freePay();
        }
        
        let date = new Date().getTime() + "";
        this.memberCount += +date.substring(7, date.length - 1);
        this.memberCount = this.thousandSeparator(this.memberCount);
        if (this.userPassedOnBoardingTypesFromMixin.length > 1) {
            this.checkPay();
        }
        this.$store.dispatch("set", {
            name: "nextOnboardingStepIndex",
            value: 11,
        });
        this.interval = setInterval(() => {
            this.timer -= 1000;
            localStorage.saleTimer = this.timer / 60 / 1000;
            if (this.timer <= 0) {
                clearInterval(this.interval);
                this.interval = null;
            }
        }, 1000);
        this.getPayLink();
    },
    destroyed() {
        clearInterval(this.illustrationInterval);
    },
    methods: {
        confirmResetTest() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("recreate-text.confirm-title"),
                text: this.locale("recreate-text.confirm-desc"),
                buttons: true,
                confirmText: this.locale("recreate-text.confirm-btn"),
                callback: this.onBoardingReset,
            });
        },
        async onBoardingReset() {
            await this.$apollo
                .mutate({
                    mutation: gql(onBoardingResetMutation),
                })
                .then(() => {
                    this.redirectController();
                });
        },
        thousandSeparator(str) {
            const parts = (str + "").split(".");
            const main = parts[0];
            const len = main.length;
            let output = "";
            let i = len - 1;

            while (i >= 0) {
                output = main.charAt(i) + output;
                if ((len - i) % 3 === 0 && i > 0) {
                    output = " " + output;
                }
                --i;
            }

            if (parts.length > 1) {
                output += "." + parts[1];
            }
            return output;
        },
        async getPayLink() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 11,
                    },
                })
                .then((r) => {
                    if(r.data.onBoardingGetStep.test === "Privileged user") {
                        return this.$router.push("/welcome/professions");
                    }
                    this.link = r.data.onBoardingGetStep.test;
                });
        },
        async freePay() {
            await this.$apollo
                .mutate({
                    mutation: gql(onBoardingPassGetMutation),
                    variables: {
                        type: "PROFESSION_ORIENTATION",
                    },
                })
                .then(() => {
                    this.checkPay();
                })
                .catch((e) => {
                    if (e.graphQLErrors[0].extensions.code === 1007) {
                        this.redirectController();
                    }
                });
        },
        async pay() {
            console.log(process.env.NODE_ENV);
            if (process.env.NODE_ENV === "development") {
                await this.$apollo
                    .mutate({
                        mutation: gql(onBoardingPassGetMutation),
                        variables: {
                            type: "PROFESSION_ORIENTATION",
                        },
                    })
                    .then(() => {
                        this.$router.push(
                            "/welcome/pay-check?shp_ObPassType=1",
                        );
                    })
                    .catch((e) => {
                        if (e.graphQLErrors[0].extensions.code === 1007) {
                            this.$router.push(
                                "/welcome/pay-check?shp_ObPassType=1",
                            );
                        }
                    });
            } else {
                this.yandexGoal("click_to_payment_btn");
                window.location.href = this.link;
            }
        },
        async checkPay() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 11,
                        result: 1,
                    },
                })
                .then(() => {
                    this.redirectController();
                });
        },
        redirectToTelegram() {
            window.location.href = "https://t.me/MerlinDating_support";
        },
    },
};
</script>

<style lang="scss">
.welcome-prof-pay {
    $root: &;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__illustration-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 300px;
        height: 300px;
    }

    &__illustration {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        object-fit: cover;
    }

    &__professions-list {
        display: flex;
        justify-content: space-between;
        min-width: 320px;
        margin: -20px 0 50px;
        @media (max-width: 767px) {
            margin-bottom: 25px;
        }
    }

    &__item {
        filter: grayscale(100%);
        width: calc(100% / 3);
        transition: .4s;

        &:hover {
            filter: grayscale(0%);
        }
    }

    &__item-icon {
        width: 21px;
        height: 26px;
    }

    &__item-name {
        font-size: 14px;
        color: $gold;
        margin-top: 10px;
    }

    &__title {
        font-size: 18px;
        font-weight: bold;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    &__text {
        color: #fff;
        margin-top: 30px;
        font-weight: 400;
        font-size: 21px;
        line-height: 1.5;
        @media (max-width: 767px) {
            margin-top: 15px;
            line-height: 20px;
            font-size: 16px;
        }
    }

    &__number {
        font-size: 24px;
        line-height: 1.5;
        @media(max-width: 767px) {
            font-size: 18px;
        }
    }

    &__pay-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
    }

    &__pay-box-title {
        text-align: center;
        color: $gold;
        font-weight: bold;
    }

    &__pay-box-inner {
        position: relative;
        border: 1px solid $gold;
        padding: 20px;
        margin-top: 10px;
    }

    &__timer {
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: bold;
        color: $gold;
        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    &__price {
        text-transform: uppercase;
        font-size: 18px;
    }

    &__old-price {
        color: #e17b7b;
        text-decoration: line-through;
    }

    &__new-price {
        display: inline-block;
        color: $gold;
        margin-left: 4px;
    }

    &__pay-btn {
        margin: 20px 0;
    }

    &__help-text {
        color: #a8a8a8;
    }

    &__free-pay-box {
        max-width: 340px;
        margin-top: 50px;
    }

    &__free-pay {
        font-size: 20px;

        &.small {
            font-size: 16px;
        }

        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    &__official {
        margin-top: 30px;
    }

    &__copy {
        display: none;
        align-items: center;
        padding: 10px 20px;
        position: relative;
        margin-left: 15px;
        flex: none;
        @media (max-width: 990px) {
            display: flex;
            margin-top: 0;
            margin-left: 15px;
        }

        &:hover {
            cursor: pointer;

            #{$root} {
                &__copy-text {
                    text-shadow: 0 0 15px rgba($gold, 0.5);
                }
            }
        }

        &.active {
            #{$root} {
                &__copy-text {
                    color: $gold;
                }

                &__copy-icon {
                    fill: $gold;
                }
            }
        }
    }

    &__copy-text {
        margin-left: 7px;
        font-size: 12px;
        color: #5e5d5e;
        text-transform: uppercase;
        transition: 0.4s;
    }

    &__copy-icon {
        fill: #5e5d5e;
    }

    &__back {
        display: flex;
        align-items: center;
        margin-top: 15px;

        &:hover {
            cursor: pointer;

            #{$root} {
                &__back-icon {
                    fill: $gold;
                }

                &__back-text {
                    color: $gold;
                }
            }
        }
    }

    &__back-icon {
        position: relative;
        top: -1px;
        fill: #959595;
        width: 18px;
        height: 18px;
        margin-right: 12px;
    }

    &__back-text {
        font-size: 18px;
        color: #959595;
        transition: 0.4s;
    }

    .checkbox {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 20px 0 10px;
    }

    .checkbox__fake {
        max-width: 20px;
        height: 20px;
        flex: none;
    }

    .checkbox__text {
        min-width: 100px;
    }

    &__free-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background: rgba(0, 0, 0, 0.5);
        transition: 0.4s;
        z-index: 1000;
    }

    &__free-modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100%;
        min-height: 100%;
    }

    &__free-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 100%;
        height: 100%;
        padding: 20px;
        transition: 0.4s;
    }

    &__free-modal-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 800px;
        background: #1c1c1c;
        border: 1px solid $gold;
        padding: 40px 5%;
    }

    &__free-modal-icon-box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 74px;
        color: $gold;
        font-size: 40px;
    }

    &__free-modal-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: $gold;
    }

    &__free-modal-title {
        width: 100%;
        text-transform: uppercase;
        color: $gold;
        margin-top: 40px;
        font-size: 18px;
        text-align: left;
    }

    &__free-modal-text {
        width: 100%;
        color: #fff;
        margin-top: 40px;
        font-size: 18px;
        text-align: left;
    }

    &__free-modal-footer {
        margin-top: 40px;
    }

    &__cross-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 20px;
        fill: #777;
        transition: 0.4s;

        &:hover {
            fill: #fff;
            cursor: pointer;
        }
    }

    &__reset-test {
        padding: 10px;
        text-align: center;
        margin-top: 15px;
        color: #959595;

        &:hover {
            cursor: pointer;
        }
    }

    &__partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
    }

    &__partners-title {
        width: 100%;
        color: #fff;
        font-size: 12px;
    }

    &__item {
        width: 20%;
        margin-top: 20px;
        @media (max-width: 767px) {
            width: 33%;
        }
    }

    &__item-img {
        height: 40px;
    }

    &__item-text {
        color: #a8a8a8;
        font-size: 10px;
        margin-top: 10px;
    }
}
</style>
