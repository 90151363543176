<template lang="pug">
  welcome-info
    template(v-slot:title)
      .welcome-analyze-title {{ locale("title") }}
        span {{ dots }}
    template(v-slot:content)
      vue-lottie-player(autoplay controls loop mode="normal" :animationData="analyzeFile" width="320" height="320").welcome-analyze-player
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import VueLottiePlayer from "vue-lottie-player";

export default {
    name: "WelcomeAnalyze",
    components: { WelcomeInfo, VueLottiePlayer },
    data() {
        return {
            dots: ".",
            analyzeFile: require("./lottie-files/analyze"),
        };
    },
    async mounted() {
        const completedSteps = await this.getOnBoardingCompletedSteps();
        const funnelType =
            completedSteps.find((step) => step.step_number === 1) || 0;

        setInterval(() => {
            if (this.dots.length >= 3) {
                this.dots = "";
            } else {
                this.dots += ".";
            }
        }, 600);
        
        setTimeout(() => {
            if (funnelType.step_result_int === 60) {
                this.$router.push("/welcome/anfas");
            } else if (funnelType.step_result_int === 62) {
                this.$router.push("/welcome/business-card-info");
            } else {
                this.$router.push("/welcome/test-complete");
            }
        }, 5000);
    },
};
</script>

<style lang="scss">
.welcome-analyze-title {
    span {
        display: inline-block;
        width: 20px;
        text-align: left;
    }
}

.welcome-analyze-player {
}
</style>
