<template lang="pug">
  .welcome-anfas
    transition(name="fadeIn")
      loading(v-if="isLoading")._loading
    welcome-info
      template(v-slot:title) Отлично, теперь убедимся, что вы реальный человек
      template(v-slot:subtitle) Merlin запомнит Вас и предупредит, если с Вами проверят деловую совместимость.
      template(v-slot:content)
        change-photos(:show-anfas="false" :show-gift="false")
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import ChangePhotos from "@/components/person/change-photos/change-photos";
import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";

export default {
    name: "WelcomeStart",
    components: { ChangePhotos, WelcomeInfo },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        profile: (state) => state.myPerson.photos.profile,
    },
    watch: {
        profile() {
            this.completeStep();
        },
    },
    mounted() {
        if (this.profile) {
            this.completeStep();
        }
    },
    methods: {
        async completeStep() {
            this.isLoading = true;
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 8,
                        result: 1,
                    },
                })
                .then(() => {
                    this.redirectController();
                });
        },
    },
};
</script>

<style lang="scss">
.welcome-anfas {
    position: relative;

    .loading {
        position: absolute;
        opacity: 0.9;
    }
}
</style>
