<template lang="pug">
    welcome-info
        template(v-slot:title) Этот человек уже зарегистрирован на Merlinface.com
        template(v-slot:subtitle) Если Вы потеряли доступ к своему старому аккаунту,
            br/ или Вы считаете что произошла ошибка - напиши в службу поддержки.
        template(v-slot:content) Для того, чтобы агент службы поддержки мог помочь вам, пожалуйста пришлите ему уникальный код вашей ошибки:
            br/
            .code(@click.stop.prevent="copyLink")
                input._input(:value="code" autocomplete="new-password" readonly)
                ._copy(:class="{active: justCopied}")
                    svg-icon(icon-name="copy")._copy-icon
                    ._copy-text
                        template(v-if="!justCopied") скопировать
                        template(v-else) скопировано
            ui-button(text="Написать в поддержку" @click.native="goToTelegram()")
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomeProfileAlreadyExist",
    components: { UiButton, WelcomeInfo },
    data() {
        return {
            justCopied: false,
        };
    },
    computed: {
        code: (state) => state.$route.query.code,
    },
    methods: {
        goToTelegram() {
            window.open(this.SUPPORT_LINK, "_blank");
        },
        copyLink() {
            let input = document.querySelector(".code__input");
            input.select();
            try {
                document.execCommand("copy");
                this.justCopied = true;
                input.blur();
                setTimeout(function () {
                    this.justCopied = false;
                }, 2000);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style lang="scss">
.code {
    $root: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: none;
    border: 1px solid #525152;
    color: $gold;
    clip-path: polygon(
        100% 0,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        0 100%,
        0 0
    );
    font-size: 14px;
    text-transform: uppercase;
    width: 100%;
    max-width: 450px;
    transition: 0.4s;
    margin: 20px 0 40px 0;

    &:after {
        content: "";
        position: absolute;
        right: -8px;
        bottom: -7px;
        width: 14px;
        height: 14px;
        transform: rotateZ(45deg);
        background: #222;
        border-left: 1px solid #525152;
        transition: 0.4s;
    }

    &__input {
        background: transparent;
        border: 0;
        cursor: default;
        font-size: 13px;
        color: #cdcdcd;
        flex-grow: 1;
        padding: 5px 20px;

        @media (max-width: 990px) {
            padding: 5px 10px;
        }

        &::selection {
            background: transparent;
        }

        &::-moz-selection {
            background: transparent;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__copy {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        position: relative;
        margin-left: 15px;
        flex: none;
        @media (max-width: 990px) {
            margin-top: 0;
            margin-left: 15px;
        }

        &:hover {
            cursor: pointer;

            #{$root} {
                &__copy-text {
                    text-shadow: 0 0 15px rgba($gold, 0.5);
                }
            }
        }

        &.active {
            #{$root} {
                &__copy-text {
                    color: $gold;
                }

                &__copy-icon {
                    fill: $gold;
                }
            }
        }
    }

    &__copy-text {
        margin-left: 7px;
        font-size: 12px;
        color: #5e5d5e;
        text-transform: uppercase;
        transition: 0.4s;
    }

    &__copy-icon {
        fill: #5e5d5e;
    }
}
</style>
