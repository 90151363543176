<template lang="pug">
  .change-name
    //._head
    //    router-link(:to="{name: 'home'}")._back(@click="back()")
    //        svg-icon(icon-name="arrow-left")._back-icon
    //        ._back-text Вернуться в профиль
    //    ._head-title Введите ваше имя и фамилию, которые будут
    //        br/ видеть другие пользователи:
    form._content(@submit.prevent="setName")
      input(
        v-model="personName"
        @input="$v.personName.$touch()"
        type="text"
        maxlength="255"
        :placeholder="locale('placeholder')"
      )._input
      ._input-error(v-if="$v.personName.$dirty && $v.personName.$invalid") {{ locale("error") }}
      ui-button(:text="btnText" type="submit" :disabled="saving || ($v.personName.$dirty && $v.personName.$invalid)")._btn
</template>

<script>
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import gql from "graphql-tag";
import UiButton from "../../ui/ui-button/ui-button";

export default {
    name: "ChangeName",
    components: { UiButton },
    data() {
        return {
            personName: null,
            saving: false,
        };
    },
    validations() {
        return {
            personName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(40),
            },
        };
    },
    computed: {
        btnText: (state) => (state.saving ? state.locale("saving") : state.locale("save")),
    },
    created() {
        this.personName =
            this.myUser.display_name === "Newbie"
                ? ""
                : this.myUser.display_name;
    },
    methods: {
        async setName() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            } else {
                this.saving = true;
                let personId = this.$store.getters["person/uuid"];

                if (!personId) {
                    personId = this.$store.getters["user/uuid"];
                }

                if (!personId) {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("reEnter"),
                        "error",
                    );
                }

                const mutation = gql`
                    mutation personEditPersonalData(
                        $personId: String!
                        $name: String!
                    ) {
                        personEditPersonalData(
                            person_id: $personId
                            input: { name: $name }
                        ) {
                            personal_data {
                                gender
                            }
                        }
                    }
                `;
                await this.$apollo
                    .mutate({
                        mutation,
                        variables: {
                            personId,
                            name: this.personName,
                        },
                    })
                    .then(() => {
                        this.saving = false;
                        this.$store.dispatch("user/setName", this.personName);
                        this.$emit("complete");
                    })
                    .catch((e) => {
                        const code = e.graphQLErrors[0].extensions.code;
                        let title = "error",
                            text = "badName";
                        this.saving = false;

                        if (code === 1013) {
                            text = "nameLimit";
                        }
                        console.log(e.graphQLErrors[0]);
                        this.$msgBox(
                            this.localeError(title),
                            this.localeError(text),
                        );
                    });
            }
        },
    },
};
</script>

<style lang="scss">
@import "change-my-name";

button {
    position: relative;
}
</style>
